import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
// import { EmploymentType, BasicDetailConfig, validationForFields, EMAIL_VALIDATION, PASSWORD_VALIDATION, Role, RoleId } from '../../config/constant';//removed warning
import { EmploymentType, BasicDetailConfig, validationForFields, EMAIL_VALIDATION, Role, ADMIN_ROLES, TENANT,MOBILE_VALIDATION} from '../../config/constant';
import InputField from '../element/Input';
import { JoiningDate, Anniversary, DOBDate, Calender3 } from './DatePicker'
import MasterService from '../../services/MasterService';
// import DealerService from '../../services/DealerService';//removed warning
import ToggleSwitch from '../element/ToggleSwitch';
// import { Tab } from 'react-bootstrap';//removed warning
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import WithRights from "../common/WithRights";
import { useHasRight } from '../hooks/useHasRights';

const { ValueContainer, Placeholder } = components;




const BasicDetails = (props) => {
    const { stateList, user_detail, ConfigList } = props
    // let prev_and_conf_errors = props
    const [bankList, setBankList] = useState([])
    const [ReferralName, setReferralName] = useState([])
    const [basic_details, setBasicDetail] = useState({ password: "", confirm_password: "", incentive_percentage: 0 });
    // const [is_existing, setIsExisting] = useState(false);//removed warning
    const [errors, SetErrors] = useState(props.location_state && props.location_state.tempErrors ? props.location_state.tempErrors : {});
    const [cityList, setCityList] = useState([])
    const [loader, setLoader] = useState(false)
    const [current_password, setCurrentPassword] = useState("")
    const [change_password, setChangePassword] = useState(false)
    const [localityList, setLocalityList] = useState([])
    const [existing_errors, SetExistingErrors] = useState({});
    // const [password_type, setPasswordType] = useState("password")//removed warning
    // const [confirm_password_type, setConfirmPasswordType] = useState("password")//removed warning
    // const [is_edit, setEdit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)//removed warning
    const [is_edit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)
    const [account_length, setAccountLength] = useState(0)
    // const [show_password_value, setShowPasswordValue] = useState(true)//removed warning
    // const [show_confirm_password_value, setShowConfirmPasswordValue] = useState(true)//removed warning
    // const [enable_new_password, setEnableNewPassword] = useState(false)//removed warning
    const [enable_agent_wallet] = useState(user_detail && user_detail.basic_details && user_detail.basic_details.agent_wallet ? 0 : 1)
    const [enable_whatsapp_mobile] = useState(user_detail && user_detail.basic_details && user_detail.basic_details.whatsapp_mobile_number ? 1 : 0)
    const [enable_bank_info, setEnableBankInfo] = useState(true)
    const [enable_mobile, setEnableMobile] = useState(true)
    const [designationList, setDesignationList] = useState([])
    const [agentWallet, setAgentWallet] = useState(false);
    const [sources, setSources] = useState([]);
    const [agentReferralList, setAgentReferralList] = useState({});

    const { adminRoleId } = useHasRight();
    const historyLead = useNavigate();

    useEffect(() => {
        if (ConfigList.length === 0) {
            props.handleReset()
            if (is_edit) {
                // historyLead('role_details?id=' + user_detail.user_id);
                historyLead('/edit-user/role_details?id=' + user_detail.user_id);
            } else {
                // historyLead('role_details');
                historyLead('/add-user/role_details');
            }
        }
        else if (!user_detail.role_details) {
            props.handleReset()
            if (is_edit) {
                // historyLead('role_details?id=' + user_detail.user_id);
                historyLead('/edit-user/role_details?id=' + user_detail.user_id);
            } else {
                // historyLead('role_details');
                historyLead('/add-user/role_details');
            }
        } else {
            if (user_detail.basic_details) {
                getBasicDetails(user_detail.basic_details)
                getBankList(user_detail.basic_details)
                let occupation_id_data = ConfigList.current_occupation.filter(e => e.value === user_detail.basic_details.current_occupation)
                let occupation_id = occupation_id_data && occupation_id_data.length ? occupation_id_data[0].id : ""
                getDesignationList(occupation_id)
            } else {
                getBankList()
                getDesignationList(0)
            }
            if (user_detail?.role_details?.role === Role.Agent) {
                getAgentReferralList()
            }
            getReferralUserList()
            getSources();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_detail, props, historyLead, ConfigList]);

    const getBasicDetails = async (basic_details) => {
        setLoader(true)
        getCityAndLocalityData(basic_details, true)
    }

    const getCityAndLocalityData = async (basic_details, setData) => {
        let temp_basic_details = basic_details
        // let business_line_index = ConfigList.business_line.findIndex((e) => e.value === basic_details.business_line)
        // if (business_line_index === -1) {
        //     temp_basic_details["business_line"] = ""
        // }
        let employment_type_index = EmploymentType.findIndex((e) => e.value === basic_details.employment_type)
        if (employment_type_index === -1) {
            temp_basic_details["employment_type"] = ""
        }
        if (temp_basic_details.password) {
            setCurrentPassword(temp_basic_details.password)
            // setEnableNewPassword(true)//removed warning
        }
        if (is_edit) {
            // setShowPasswordValue(false)//removed warning
            // setShowConfirmPasswordValue(false)//removed warning
            // temp_basic_details["password"] = ""
            // temp_basic_details["confirm_password"] = ""
        }
        await getCityList([basic_details.state_name], setData, temp_basic_details)

    }

    const getSources = async () => {
        try {
            let response = await MasterService.post('/sfa/user/get_sources_config', { role_id: user_detail.role_details.role });
            if (response && response.data && response.data.status === 200) {
                if(response.data.data){
                    setSources(response.data.data);
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const getAgentReferralList = async () => {
        try {
            let manager_id = user_detail?.role_details?.reporting_manager || 0
            if (manager_id) {
                let response = await MasterService.post('/sfa/user/get_eca_agent_list', { manager_id: manager_id });
                if (response && response.data && response.data.status === 200) {
                    if (response.data.data) {
                        let responseData = response.data.data.filter(item => !Array.isArray(item));
                        if (user_detail?.basic_details?.eca_agent) {
                            responseData.push(user_detail.basic_details.eca_agent)
                        }
                        if (user_detail?.user_id) {
                            responseData = responseData.filter(item => item.user_id !== user_detail.user_id);
                        }
                        responseData = responseData.map(item => {
                            return {
                                value: item.user_id,
                                label: item.name
                            }
                        })
                        responseData = responseData.filter(item => item.value && item.label);
                        setAgentReferralList(responseData);
                    }
                } else {
                    toast.error(response.data.message);
                }
            }

        } catch (error) {
            toast.error(error)
        }
    }

    const getCityList = async (state_ids, setData, basic_details) => {
        let temp_basic_details = basic_details
        await MasterService.post('/sfa/user/city_list', { state_ids })
            .then(async function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data ? response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id })) : [];
                    let city_index = city_list.findIndex(e => e.id === basic_details.city)
                    if (city_index === -1) {
                        temp_basic_details["city"] = ""
                    }

                    setCityList(city_list);
                    if (setData === true) {
                        await MasterService.getLocalityList(basic_details.city).then((res) => {
                            let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                            setLocalityList(locality_list)
                            let locality_index = locality_list.findIndex(e => e.id === basic_details.user_location)
                            if (locality_index === -1) {
                                temp_basic_details["user_location"] = ""
                            }

                            setBasicDetail(temp_basic_details)
                        })
                    } else {
                        setBasicDetail(temp_basic_details)
                    }
                    if (validationForFields.agent_wallet_view.includes(parseInt(user_detail.role_details?.role))) {
                        let check_open_application = await CheckPendingApplication(false, "bank")
                        setEnableBankInfo(check_open_application)
                        let mobile_open_application = await CheckPendingApplication(false, "mobile")
                        setEnableMobile(mobile_open_application)
                    }
                }
            })
            .catch(function (response) {
            });
        setLoader(false)
    }

    const getBankList = async (temp_basic_details = {}) => {
        MasterService.post('/sfa/user/bank-list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var bank_list = response.data.data.map(elm => ({ id: elm.bankId, label: elm.bankName, value: elm.bankId, valid_length: elm.valid_length }));
                    if (Object.keys(temp_basic_details).length && temp_basic_details.bank_id) {
                        var bank_list_selected = bank_list.filter(elm => elm.id === temp_basic_details.bank_id);
                        let account_length = bank_list_selected && bank_list_selected.length ? bank_list_selected[0].valid_length : 0
                        setAccountLength(account_length)
                    }
                    setBankList(bank_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getDesignationList = async (occupation_id) => {
        if (occupation_id) {
            MasterService.post('/sfa/user/designation-list', { occupation_id })
                .then(function (response) {
                    if (response.data.status === 200) {
                        var designation_list = response.data.data.map(elm => ({ id: elm.value, label: elm.label, value: elm.value }));
                        setDesignationList(designation_list);
                    }
                })
                .catch(function (response) {
                });
        }
    }

    const getReferralUserList = async () => {
        MasterService.post('/sfa/user/get_referral_user', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var referral_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name + " (" + elm.id + ")", value: elm.id }));
                    setReferralName(referral_list);
                }
            })
            .catch(function (response) {
            });
    }

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    const promiseOptions = async (inputValue) => {
        return new Promise(async (resolve, reject) => {
            if (inputValue.trim().length > 2) {
                resolve(filterData(inputValue));
            }
        });
    };


    const filterData = (inputValue) => {
        return ReferralName.filter(option =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    const CheckPendingApplication = async (show_error, type) => {
        setLoader(true)
        let open_lead_and_reportee_data = user_detail && user_detail.open_lead_and_reportee_data ? user_detail.open_lead_and_reportee_data : {}

        let params = {
            user_id: user_detail.user_id,
            role_id: user_detail.role_details.role
        }
        let ready_to_wallet_change = false

        params.user_id = parseInt(params.user_id);
        let pending_loan_res = open_lead_and_reportee_data && open_lead_and_reportee_data.pendingRefinanceLeads ? open_lead_and_reportee_data.pendingRefinanceLeads : []
        let pending_lead_res = open_lead_and_reportee_data && open_lead_and_reportee_data.pendingLoanboxLeads ? open_lead_and_reportee_data.pendingLoanboxLeads : []

        let errArr = {
            ucrf: [],
            ubrf: [],
            ucf: []
        };
        let errorLoan = false;
        let errorLead = false;
        let isCheckedLoan = false;
        let isCheckedLead = false;
        let wallet_balance = 0
        let freeze_account = 0

        if (pending_loan_res && pending_loan_res.data && pending_loan_res.status === 200) {
            isCheckedLoan = true;
            for (const key of ['UCRF', 'UBRF']) {
                if (pending_loan_res && pending_loan_res.data && pending_loan_res.data[key]) {
                    for (const item of pending_loan_res.data[key]) {
                        if(item.is_account_freeze){
                            freeze_account = 1
                        }
                        for (const [k, v] of Object.entries(item['validation'])) {
                            if (v === 1) {
                                errorLoan = true;
                                errArr[`${key.toLowerCase()}`].push(k);
                            }
                        }
                    }
                }
            }
        }

        if (pending_lead_res && pending_lead_res.data && pending_lead_res.status === 200) {
            isCheckedLead = true;
            if (pending_lead_res && pending_lead_res.data && pending_lead_res.data) {
                for (const item of pending_lead_res.data) {
                    if (item.is_wallet_amount) {
                        wallet_balance = item.is_wallet_amount
                        setAgentWallet(true);
                    }
                    if(item.is_account_freeze){
                        freeze_account = 1
                    }
                    for (const [k, v] of Object.entries(item['validation'])) {
                        if (v === 1) {
                            errorLead = true;
                            errArr['ucf'].push(k);
                        }
                    }
                }
            }
        }

        if (errArr.ucrf.length !== 0 || errArr.ubrf.length !== 0 || errArr.ucf.length !== 0) {
            for (const [k, v] of Object.entries(errArr)) {
                if (v.length !== 0 && show_error) {
                    toast.error(`Error: ${v.join(',')} in ${k.toUpperCase()}`)
                }
            }
        }

        // if (type != "mobile" && !wallet_balance && !errorLead && !errorLoan && isCheckedLead && isCheckedLoan) {
        //     ready_to_wallet_change = true
        // }
        if(type === 'bank' && !freeze_account){
            ready_to_wallet_change = true
        }
        if (type === "mobile" && !wallet_balance) {
            ready_to_wallet_change = true
        }
        setLoader(false)
        return ready_to_wallet_change
    }

    const handleInputChange = async (target_name, type, event) => {
        let temp_basic_details = { ...basic_details };
        console.log("basic_details",basic_details);
        let tempErrors = { ...errors }
        tempErrors[target_name] = ""
        if (target_name === "state_name") {
            if (temp_basic_details.state_name !== event.value) {
                temp_basic_details[target_name] = event.value;
                tempErrors[target_name] = ""
                temp_basic_details["city"] = ""
                tempErrors["city"] = ""
                temp_basic_details["user_location"] = ""
                tempErrors["user_location"] = ""
                await getCityList([event.value], false, temp_basic_details)
                await MasterService.getLocalityList(event.value).then((res) => {
                    let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                    setLocalityList(locality_list)
                })
            }
        }

        else if (target_name === "city") {
            if (temp_basic_details.city !== event.value) {
                tempErrors[target_name] = ""
                temp_basic_details["user_location"] = ""
                tempErrors["user_location"] = ""
                temp_basic_details[target_name] = event.value;
                MasterService.getLocalityList(event.value).then((res) => {
                    let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                    setLocalityList(locality_list)
                })
            }
        }
        else if (target_name === "referral_name") {
            temp_basic_details[target_name] = event.id;
        }
        else if (target_name === "current_occupation") {
            temp_basic_details[target_name] = event.value;
            temp_basic_details["designation"] = ""
            let event_id_data = ConfigList.current_occupation.filter(e => e.value === event.value)
            let event_id = event_id_data && event_id_data.length ? event_id_data[0].id : ""
            getDesignationList(event_id)
        }
        else if (type === "checkbox" || type === "select") {
            if (target_name === "bank_id") {
                setAccountLength(event.valid_length)
                temp_basic_details["bank_account_number"] = "";
                temp_basic_details["confirm_bank_account_number"] = "";
                temp_basic_details["bank_branch_name"] = "";
                temp_basic_details["beneficiary_name"] = "";
                // temp_basic_details["npwp"] = "";

                tempErrors["bank_account_number"] = ""
                tempErrors["confirm_bank_account_number"] = ""
                tempErrors["bank_branch_name"] = ""
                tempErrors["beneficiary_name"] = ""
                tempErrors["tagging_id"] = ""
                // tempErrors["npwp"] = ""
                temp_basic_details[target_name] = event.value;

            } else if (target_name === "tagging_id") {
                temp_basic_details["eca_agent"] = "";
                temp_basic_details[target_name] = event.value;                
                if((basic_details.eca_validate_tagging_id !="eca" && basic_details.eca_validate_tagging_id !="eca_l1") &&  (event.value=="eca" || event.value=="eca_l1")){
                    tempErrors["tagging_id"] = 'ECA tagging not valid! Choose Different Tagging';
                    temp_basic_details[target_name] = "";
                }
            } else if (target_name === 'eca_agent') {
                temp_basic_details[target_name] = { user_id: event.value, name: event.label };
            } else {
                temp_basic_details[target_name] = event.value;
            }

        } else if (type === "toggle") {
            temp_basic_details[target_name] = event;
        } else if (type === "number") {
            temp_basic_details[target_name] = event.formattedValue;
        } else if (target_name === "beneficiary_name") {
            temp_basic_details[target_name] = event.target.value;
        } else if (target_name === "agent_wallet") {
            // let check_open_application = await CheckPendingApplication(true, "") 
            // if(check_open_application){
            temp_basic_details[target_name] = !temp_basic_details[target_name];
            // }
        } else if (target_name === "is_subscribe_dif") {
            temp_basic_details[target_name] = event.target.checked ? 1 : 0;
        } else {
            temp_basic_details[target_name] = event.target.value;
        }

        if (target_name === "password" || target_name === "confirm_password") {
            setChangePassword(true)
            // if (target_name === "password") setShowPasswordValue(true)//removed warning
            // if (target_name === "confirm_password") setShowConfirmPasswordValue(true)//removed warning
        }
       // tempErrors[target_name] = ""

        if (target_name === "ktp_id") {
            if (isNaN(event.target.value)) {
                tempErrors["ktp_id"] = 'KTP ID must be numeric';
            } else if (event.target.value.length !== 16) {
                tempErrors["ktp_id"] = 'KTP ID must be of 16 digits';
            } else {
                delete tempErrors[target_name]
            }
        }
        SetErrors(tempErrors)
        setBasicDetail(temp_basic_details);
    }

    const handleDateChange = (value, name) => {
        let temp_basic_details = { ...basic_details };
        let tempError = { ...errors };
        temp_basic_details[name] = value;
        tempError[name] = '';
        SetErrors(tempError);
        setBasicDetail(temp_basic_details);
    }

    const handleChangeImage = (event) => {
        let filesInfo = Array.from(event.target.files);
        if (filesInfo && filesInfo.length) {
            filesInfo.forEach((file, index) => {
                let browse_url = URL.createObjectURL(file);
                let temp_basic_details = { ...basic_details };
                temp_basic_details["profile_image"] = browse_url;
                temp_basic_details["profile_image_file"] = file;
                setBasicDetail(temp_basic_details);
            });
        }
    }

    const handleClickCancel = () => {
        if (user_detail?.user_id) {
            historyLead('/view-user/' + user_detail?.user_id_hash, { replace: true })
        }
    }

    const handleClick = async (is_skip) => {
        let res = await isValidate()
        setLoader(false)
        if (res.formIsValid) {
            let temp_basic_details = { ...basic_details }
            temp_basic_details.current_password = current_password
            temp_basic_details.change_password = change_password
            if (temp_basic_details.password.trim() === "") {
                temp_basic_details.password = current_password
            }
            await props.handleSave(temp_basic_details, 'basic_details', is_skip)
            if (!is_skip) {
                if (is_edit) {
                    // historyLead('documents?id=' + user_detail.user_id);
                    historyLead('/edit-user/documents?id=' + user_detail.user_id_hash);
                } else {
                    // historyLead('documents');
                    historyLead('/add-user/documents');
                }
            }
        }
    }

    const isValidate = async () => {
        setLoader(true)
        let mobileRule = MOBILE_VALIDATION;
        let tempBasicDetail = { ...basic_details };
        let formIsValid = true;
        let tempErrors = {};
        let tempExistingErrors = { ...existing_errors };
        let res = await checkExisting()
        if (!tempBasicDetail["username"]) {
            formIsValid = false;
            tempErrors["username"] = 'Username is required';
        }
        if (!tempBasicDetail["mobile_no"]) {
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile is required';
        } else if (tempBasicDetail["mobile_no"] && !mobileRule.test(tempBasicDetail["mobile_no"])) {
            formIsValid = false;
            tempErrors["mobile_no"] = 'Please enter a number between 8 - 12 characters and starting with 8';
        } else if(tempBasicDetail["alternative_number"] && tempBasicDetail["alternative_number"] === tempBasicDetail["mobile_no"]){
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile and alternative number must be different';
        }
        if(tempBasicDetail["alternative_number"] && tempBasicDetail["alternative_number"].trim().length > 0){
            if (tempBasicDetail["alternative_number"] && !mobileRule.test(tempBasicDetail["alternative_number"])) {
                formIsValid = false;
                tempErrors["alternative_number"] = 'Please enter a number between 8 - 12 characters and starting with 8';
            }            
        } 
        if(tempBasicDetail["whatsapp_mobile_number"] && tempBasicDetail["whatsapp_mobile_number"].trim().length > 0){
            if (tempBasicDetail["whatsapp_mobile_number"] && !mobileRule.test(tempBasicDetail["whatsapp_mobile_number"])) {
                formIsValid = false;
                tempErrors["whatsapp_mobile_number"] = 'Please enter a number between 8 - 12 characters and starting with 8';
            }           
        } 
        if (!tempBasicDetail["email_id"]) {
            formIsValid = false;
            tempErrors["email_id"] = 'Email is required';
        }
        else if (tempBasicDetail["email_id"].match(EMAIL_VALIDATION) == null) {
            tempErrors["email_id"] = 'Email is not valid';
        }
        if (tempBasicDetail["npwp"] && tempBasicDetail["npwp"].replace(/[^0-9]/g, "").length < 15) {
            tempErrors["npwp"] = 'NPWP is not valid';
        }
        if (!tempBasicDetail["ktp_id"]) {
            formIsValid = false;
            tempErrors["ktp_id"] = 'KTP ID is required';
        } else if (isNaN(tempBasicDetail["ktp_id"])) {
            formIsValid = false;
            tempErrors["ktp_id"] = 'KTP ID must be numeric';
        } else if (tempBasicDetail["ktp_id"].length !== 16) {
            formIsValid = false;
            tempErrors["ktp_id"] = 'KTP ID must be of 16 digits';
        } else {
            delete tempErrors["ktp_id"]
            delete tempExistingErrors["ktp_id"]
        }
        if (!tempBasicDetail["joining_date"]) {
            formIsValid = false;
            tempErrors["joining_date"] = 'Joining Date is required';
        }
        if (!tempBasicDetail["employment_type"]) {
            formIsValid = false;
            tempErrors["employment_type"] = 'Employment Type is required';
        }
        if (parseInt(tempBasicDetail["employment_type"]) === 1 && !tempBasicDetail["employee_id"]) {
            formIsValid = false;
            tempErrors["employee_id"] = 'Employee Id is required';
        }
        if (!tempBasicDetail["bank_id"]) {
            formIsValid = false;
            tempErrors["bank_id"] = 'Bank Name is required';
        }
        if (!tempBasicDetail["bank_branch_name"]) {
            formIsValid = false;
            tempErrors["bank_branch_name"] = 'Branch is required';
        }

        if (!tempBasicDetail["bank_account_number"]) {
            formIsValid = false;
            tempErrors["bank_account_number"] = 'Account Number is required';
        } else if (tempBasicDetail["bank_account_number"].length !== account_length && account_length > 0) {
            formIsValid = false;
            tempErrors["bank_account_number"] = 'Format of Account Number must ' + account_length + ' digits';
        }
        if (!tempBasicDetail["confirm_bank_account_number"]) {
            formIsValid = false;
            tempErrors["confirm_bank_account_number"] = 'Confirm Account Number is required';
        } else if (tempBasicDetail["confirm_bank_account_number"].length !== account_length && account_length > 0) {
            formIsValid = false;
            tempErrors["confirm_bank_account_number"] = 'Format of Account Number must ' + account_length + ' digits';
        } else if (!(tempBasicDetail["bank_account_number"].trim() === "" && tempBasicDetail["confirm_bank_account_number"].trim() === "") && tempBasicDetail["confirm_bank_account_number"] !== tempBasicDetail["bank_account_number"]) {
            formIsValid = false;
            tempErrors["confirm_bank_account_number"] = 'Account Number not matching';
        }
        if (!tempBasicDetail["beneficiary_name"]) {
            formIsValid = false;
            tempErrors["beneficiary_name"] = 'Beneficiary Name is required';
        }
        if (!tempBasicDetail["date_of_birth"]) {
            formIsValid = false;
            tempErrors["date_of_birth"] = 'DOB is required';
        }
        if (!tempBasicDetail["state_name"]) {
            formIsValid = false;
            tempErrors["state_name"] = 'State is required';
        }
        if (!tempBasicDetail["city"]) {
            formIsValid = false;
            tempErrors["city"] = 'City is required';
        }
        if (!tempBasicDetail["postal_code"]) {
            formIsValid = false;
            tempErrors["postal_code"] = 'Postal Code is required';
        }
        if (!tempBasicDetail["address"]) {
            formIsValid = false;
            tempErrors["address"] = 'Street address is required';
        }
        // if (!is_edit && !tempBasicDetail["password"]) {
        //     formIsValid = false;
        //     tempErrors["password"] = 'Password is required';

        // }
        // if (!is_edit && !tempBasicDetail["confirm_password"]) {
        //     formIsValid = false;
        //     tempErrors["confirm_password"] = 'Confirm Password is required';
        // }
        // if (!(tempBasicDetail["password"].trim() === "" && tempBasicDetail["confirm_password"].trim() === "")) {
        //     if (tempBasicDetail["confirm_password"] === tempBasicDetail["password"]) {
        //         if (show_password_value && show_confirm_password_value) {
        //             let result = validatePassword(tempBasicDetail["password"]);
        //             if (result !== "") {
        //                 tempErrors["password"] = result
        //                 formIsValid = false;
        //             }
        //         }
        //     }else{
        //         formIsValid = false;
        //         tempErrors["confirm_password"] = 'Password does not match ';
        //     }
        // }

        if ((!tempBasicDetail["current_occupation"] || tempBasicDetail["current_occupation"] == 0 || ConfigList.current_occupation.filter((e) => e.value === tempBasicDetail.current_occupation).length === 0) && validationForFields.current_occupation.includes(user_detail.role_details.role)) {
            formIsValid = false;
            tempErrors["current_occupation"] = 'Current Occupation is required';
        }
        // if (!tempBasicDetail["company_name"] && validationForFields.company_name.includes(user_detail.role_details.role) && parseInt(user_detail.role_details.role) === parseInt(Role.Agent)) {
        //     formIsValid = false;
        //     tempErrors["company_name"] = 'Company Name is required';
        // }
        if ((!tempBasicDetail["designation"] || tempBasicDetail["designation"] == 0 || designationList.filter((e) => e.value === tempBasicDetail.designation).length === 0) && validationForFields.designation.includes(user_detail.role_details.role)) {
            formIsValid = false;
            tempErrors["designation"] = 'Designation is required';
        }
        if (!tempBasicDetail["tagging_id"] && validationForFields.tagging.includes(user_detail.role_details.role)) {
            formIsValid = false;
            tempErrors["tagging_id"] = 'Tagging is required';
        } else if(tempBasicDetail["tagging_id"] == "eca" && (!tempBasicDetail["eca_agent"] || Object.keys(tempBasicDetail["eca_agent"]).length === 0)){
            tempErrors["eca_agent"] = 'Referral Agent is required';
        }
        if ((!tempBasicDetail["source"] || tempBasicDetail["source"] == 0 || sources.filter((e) => e.value === basic_details.source).length === 0) && validationForFields.source.includes(user_detail.role_details.role)) {
            formIsValid = false;
            tempErrors["source"] = 'Source is required';
        }
        // if (!tempBasicDetail["referral_name"] && validationForFields.referral_name.includes(user_detail.role_details.role)) {
        //     formIsValid = false;
        //     tempErrors["referral_name"] = 'Referral Name is required';
        // }


        if (validationForFields.rt.includes(user_detail.role_details.role)) {
            if (!tempBasicDetail["rt"]) {
                formIsValid = false;
                tempErrors["rt"] = 'RT is required';
            } else if (tempBasicDetail["rt"].length < 2) {
                tempErrors["rt"] = 'RT length must be 2';
            }
        }
        if (validationForFields.rw.includes(user_detail.role_details.role)) {
            if (!tempBasicDetail["rw"]) {
                formIsValid = false;
                tempErrors["rw"] = 'RW is required';
            } else if (tempBasicDetail["rw"].length < 2) {
                tempErrors["rw"] = 'RW length must be 2';
            }
        }
        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        if (formIsValid) {
            formIsValid = res
        } else {
            SetErrors(tempErrors);
        }
        let return_res = {
            formIsValid,
            tempErrors
        }
        return return_res;
    }

    // const validatePassword = (password) => {//removed warning
    //     let error_message = ""
    //     if (change_password && current_password === md5(password).trim()) {
    //         error_message = "Can not set old password"
    //     } else if (password && ('' + password).match(PASSWORD_VALIDATION) == null) {
    //         error_message = "Should be of at least 8 characters. Must include 1 lowercase,1 uppercase,1 digit and 1 special character";
    //     }
    //     return error_message
    // }

    const checkExisting = async (event) => {
        let formIsValid = true;
        let tempErrors = { ...errors };
        let tempExistingErrors = { ...existing_errors };
        // let data_exist = false//removed warning
        let call_api = true
        let params = {}

        if (basic_details.mobile_no) {
            params.mobile_no = basic_details.mobile_no ? basic_details.mobile_no : ""
        }
        if (basic_details.alternative_number) {
            params.alternative_number = basic_details.alternative_number ? basic_details.alternative_number : ""
        } else {
            delete tempErrors["alternative_number"]
            delete tempExistingErrors["alternative_number"]
        }

        if (basic_details.whatsapp_mobile_number && validationForFields.whatsapp_mobile_number.includes(user_detail.role_details.role)) {
            params.whatsapp_mobile_number = basic_details.whatsapp_mobile_number ? basic_details.whatsapp_mobile_number : ""
        } else {
            delete tempErrors["whatsapp_mobile_number"]
            delete tempExistingErrors["whatsapp_mobile_number"]
        }

        if (basic_details.bank_account_number && basic_details.confirm_bank_account_number) {
            if (basic_details.bank_account_number.trim() !== "" && basic_details.bank_account_number.trim() === basic_details.confirm_bank_account_number.trim()) {
                params.bank_account_number = basic_details.bank_account_number ? basic_details.bank_account_number : 0
                params.bank_id = basic_details.bank_id ? basic_details.bank_id : 0
            } else {
                call_api = false
            }
        }
        if (basic_details.npwp) {
            params.npwp = basic_details.npwp ? basic_details.npwp : ""
        }
        if (basic_details.ktp_id) {
            params.ktp_id = basic_details.ktp_id && !isNaN(basic_details.ktp_id) ? basic_details.ktp_id : 0
        }
        if (basic_details.email_id) {
            params.email_id = basic_details.email_id ? basic_details.email_id : ""
        }


        if (event && event.target.name === "ktp_id") {
            if (isNaN(basic_details["ktp_id"])) {
                tempErrors["ktp_id"] = 'KTP ID must be numeric';
            } else if (basic_details["ktp_id"].length !== 16) {
                tempErrors["ktp_id"] = 'KTP ID must be of 16 digits';
            } else {
                delete tempErrors["ktp_id"]
                delete tempExistingErrors["ktp_id"]
            }
        }

        if (Object.keys(params).length && call_api) {
            if (user_detail.user_id) {
                params.user_id = user_detail.user_id_hash;

            }
            await MasterService.post('/sfa/user/check_existing', params, {}).then(function (response) {
                if (response.data.status === 200) {
                    let response_data = response.data.data
                    if (response_data.mobile) {
                        if (response_data.mobile.is_exist === true) {
                            tempErrors["mobile_no"] = response_data.mobile.error_message
                            tempExistingErrors["mobile_no"] = response_data.mobile.error_message
                        } else {
                            delete tempErrors["mobile_no"]
                            delete tempExistingErrors["mobile_no"]
                        }

                    }
                    if (response_data.alternative_number) {
                        if (response_data.alternative_number.is_exist === true) {
                            tempErrors["alternative_number"] = response_data.alternative_number.error_message
                            tempExistingErrors["alternative_number"] = response_data.alternative_number.error_message
                        } else {
                            delete tempErrors["alternative_number"]
                            delete tempExistingErrors["alternative_number"]
                        }
                    }
                    if (response_data.whatsapp_mobile_number) {
                        if (response_data.whatsapp_mobile_number.is_exist === true) {
                            tempErrors["whatsapp_mobile_number"] = response_data.whatsapp_mobile_number.error_message
                            tempExistingErrors["whatsapp_mobile_number"] = response_data.whatsapp_mobile_number.error_message
                        } else {
                            delete tempErrors["whatsapp_mobile_number"]
                            delete tempExistingErrors["whatsapp_mobile_number"]
                        }
                    }
                    if (response_data.email) {
                        if (response_data.email.is_exist === true) {
                            tempErrors["email_id"] = response_data.email.error_message
                            tempExistingErrors["email_id"] = response_data.email.error_message
                        } else {
                            delete tempErrors["email_id"]
                            delete tempExistingErrors["email_id"]
                        }
                    }
                    if (response_data.ktp_id) {
                        if (response_data.ktp_id.is_exist === true) {
                            tempErrors["ktp_id"] = response_data.ktp_id.error_message
                            tempExistingErrors["ktp_id"] = response_data.ktp_id.error_message
                        } else {
                            delete tempErrors["ktp_id"]
                            delete tempExistingErrors["ktp_id"]
                        }
                    }
                    if (response_data.npwp) {
                        if (response_data.npwp.is_exist === true) {
                            tempErrors["npwp"] = response_data.npwp.error_message
                            tempExistingErrors["npwp"] = response_data.npwp.error_message
                        } else {
                            delete tempErrors["npwp"]
                            delete tempExistingErrors["npwp"]
                        }
                    }
                    if (response_data.bank_account_number) {
                        if (response_data.bank_account_number.is_exist === true) {
                            tempErrors["bank_account_number"] = response_data.bank_account_number.error_message
                            tempExistingErrors["bank_account_number"] = response_data.bank_account_number.error_message
                        } else {
                            delete tempErrors["bank_account_number"]
                            delete tempExistingErrors["bank_account_number"]
                        }
                    }
                }
            }).catch(function (response) {
            });
            if (Object.keys(tempExistingErrors).length) {
                // data_exist = true//removed warning
                formIsValid = false
            } else {
                // data_exist = false//removed warning
                formIsValid = true
            }
            // setIsExisting(data_exist)//removed warning
            SetErrors(tempErrors);
            SetExistingErrors(tempExistingErrors)
            return formIsValid
        } else {
            return formIsValid
        }

    }

    // const changePasswordType = (type) => {//removed warning
    //     if (type === "text") {
    //         setPasswordType("password")
    //     }
    //     else if (type === "password") {
    //         setPasswordType("text")
    //     }
    // }

    // const changeConfirmPasswordType = (type) => {//removed warning
    //     if (type === "text") {
    //         setConfirmPasswordType("password")
    //     }
    //     else if (type === "password") {
    //         setConfirmPasswordType("text")
    //     }
    // }
    return (
        <div className='basic-detail-form p-xxl add-user-detail-filed'>
            {loader ? <Loader /> : null}
            {user_detail.status === "1" &&
                <button className='btn-line m-xs-l save_and_skip' onClick={handleClick.bind(this, true)}>
                    Save And Skip
                </button>
            }
            <h2>Basic Details</h2>
            <div className='agent_wallet'>
                <div className='add-photo-btn'>
                    {basic_details.profile_image && basic_details.profile_image !== "" ?
                        <div className="add-profile-photo">
                            <span className="add-img-txt">Add Profile Photo</span>
                            <span className="subheading">Only jpg and png files are accepted</span>
                            <div className="add-photo-btn">
                                <div className="preview-img">
                                    <img alt="" src={basic_details.profile_image} />
                                </div>
                                <div className="fileUpload">
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        id="re-upload-image-input"
                                        className="upload"
                                        onChange={handleChangeImage}
                                    />
                                    <span>Reupload Photo</span>
                                </div>
                            </div>
                        </div> :
                        <div className="add-profile-photo">
                            <span className="add-img-txt">Add Profile Photo</span>
                            <span className="subheading">Only jpg and png files are accepted</span>
                            <div className="add-photo-btn">
                                <div className="fileUpload">
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        id="upload-image-input"
                                        className="upload"
                                        onChange={handleChangeImage}
                                    />
                                    <span>Upload</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {(TENANT=="id") && validationForFields.agent_wallet_view.includes(parseInt(user_detail.role_details?.role)) && user_detail.status == "1" && 
                    <div className='role-detail-preview d-flex d-flex-center user-wallet'>
                        <ul>
                            <li className="custom-control custom-checkbox">
                                <span>Subscribe To :</span>
                                <input
                                    id={`agent_wallet`}
                                    type="checkbox"                                    
                                    disabled={((!enable_agent_wallet && basic_details.agent_wallet) || (user_detail.role_details.role != Role.Agent && user_detail.role_details.role != Role.ARO && user_detail.role_details.role != Role.BRO && user_detail.role_details.role != Role.BM && user_detail.role_details.role != Role.PlatinumAgent)) ? true : false}
                                    name="agent_wallet"
                                    onChange={handleInputChange.bind(null, 'agent_wallet', 'check')}
                                    className="custom-control-input"                                    
                                    checked={basic_details.agent_wallet ? true : false}
                                />

                                <label htmlFor={`agent_wallet`} className="custom-control-label">
                                    Sales Wallet
                                </label>
                            </li>
                        </ul>
                    </div>
                }
                  {TENANT == "fav-id" && validationForFields.agent_wallet_view.includes(parseInt(user_detail.role_details?.role)) && user_detail.status == "1" && 
                    <div className='role-detail-preview d-flex d-flex-center user-wallet'>
                        <ul>
                            <li className="custom-control custom-checkbox">
                                <span>Subscribe To :</span>
                                <input
                                    id={`agent_wallet`}
                                    type="checkbox"                                    
                                    disabled={((!enable_agent_wallet && basic_details.agent_wallet) || (user_detail.role_details.role != Role.FA && user_detail.role_details.role != Role.MO && user_detail.role_details.role != Role.MM && user_detail.role_details.role != Role.PlatinumAgent)) ? true : false}
                                    name="agent_wallet"
                                    onChange={handleInputChange.bind(null, 'agent_wallet', 'check')}
                                    className="custom-control-input"                                    
                                    checked={basic_details.agent_wallet ? true : false}
                                />

                                <label htmlFor={`agent_wallet`} className="custom-control-label">
                                    Sales Wallet
                                </label>
                            </li>
                        </ul>
                    </div>
                }
            </div>
            <h3>Personal Details</h3>

            <div className="row">
                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField type="text" error={(errors.username) ? errors.username : ''} name="username" id="username" label="Username" placeholder=" " value={basic_details.username} onChange={handleInputChange.bind(null, 'username', 'text')} required={true} />
                        <label data-label="Username*" className="form-label"></label>

                    </div>
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <WithRights rightKey="edit_mobile_number" fallback={
                            <>
                                <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (agentWallet || !enable_mobile || basic_details.is_mobile_no_verified === "1") ? true : false} onBlur={checkExisting} type="number" error={(errors.mobile_no) ? errors.mobile_no : ''} maxLength={12} name="mobile_no" id="mobile_no" label="Mobile" placeholder=" " value={basic_details.mobile_no} onChange={handleInputChange.bind(null, 'mobile_no', 'text')} required={true} />
                                <label data-label="Mobile*" className="form-label"></label>
                            </>
                        }>
                            <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : agentWallet} onBlur={checkExisting} type="number" error={(errors.mobile_no) ? errors.mobile_no : ''} maxLength={12} name="mobile_no" id="mobile_no" label="Mobile" placeholder=" " value={basic_details.mobile_no} onChange={handleInputChange.bind(null, 'mobile_no', 'text')} required={true} />
                            <label data-label="Mobile*" className="form-label"></label>

                        </WithRights>
                    </div>
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <WithRights rightKey="edit_user_email_id" fallback={
                            <>
                                <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : is_edit} onBlur={checkExisting} type="email" error={(errors.email_id) ? errors.email_id : ''} name="email_id" id="email_id" label="Email" placeholder=" " value={basic_details.email_id} onChange={handleInputChange.bind(null, 'email_id', 'text')} required={true} />
                                <label data-label="Email*" className="form-label"></label>
                            </>
                        }>
                            <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : agentWallet} onBlur={checkExisting} type="email" error={(errors.email_id) ? errors.email_id : ''} name="email_id" id="email_id" label="Email" placeholder=" " value={basic_details.email_id} onChange={handleInputChange.bind(null, 'email_id', 'text')} required={true} />
                            <label data-label="Email*" className="form-label"></label>
                        </WithRights>
                    </div>
                </fieldset>

                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField onBlur={checkExisting} type="number" error={(errors.ktp_id) ? errors.ktp_id : ''} name="ktp_id" id="ktp_id" label="KTP ID" placeholder=" " value={basic_details.ktp_id} onChange={handleInputChange.bind(null, 'ktp_id', 'text')} required={true} />
                        <label data-label="KTP ID*" className="form-label"></label>

                    </div>
                </fieldset>

                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator: () => null }}
                            options={EmploymentType}
                            value={EmploymentType.filter((e) => e.value === basic_details.employment_type)}
                            onChange={handleInputChange.bind(null, 'employment_type', "checkbox")}
                            placeholder="Employment Type*"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                    {
                        (errors && errors.employment_type) ? <span className="error show">{errors.employment_type}</span> : ''
                    }
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField type="text" error={(errors.employee_id) ? errors.employee_id : ''} name="employee_id" id="employee_id" label="Employee ID" placeholder=" " value={basic_details.employee_id} onChange={handleInputChange.bind(null, 'employee_id', 'text')} />
                        <label data-label={parseInt(basic_details.employment_type) === 1 ? "Employee ID*" : "Employee Id"} className="form-label"></label>
                    </div>
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <JoiningDate
                            value={basic_details.joining_date}
                            handleDateChange={handleDateChange}
                        />
                        {(basic_details.joining_date) ? <label data-label="Joining Date*" className="form-label date-label"></label> : ''}
                    </div>
                    {
                        (errors && errors.joining_date) ? <span className="error show">{errors.joining_date}</span> : ''
                    }
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <Anniversary
                            value={basic_details.anniversary_date}
                            handleDateChange={handleDateChange}
                        />
                        {(basic_details.anniversary_date) ? <label data-label="Anniversary" className="form-label date-label"></label> : ''}
                    </div>
                </fieldset>
                {(TENANT=="id" || TENANT=="fav-id") && is_edit &&
                        <fieldset className="col-md-4">
                            <div className="material">
                                <InputField disabled={enable_whatsapp_mobile && (agentWallet || !enable_mobile || basic_details.whatsapp_number_verified === "1") ? true : false} onBlur={checkExisting} type="number" error={(errors.whatsapp_mobile_number) ? errors.whatsapp_mobile_number : ''} maxLength={12} name="whatsapp_mobile_number" id="whatsapp_mobile_number" label="Whatsapp Mobile Number" placeholder=" " value={basic_details.whatsapp_mobile_number} onChange={handleInputChange.bind(null, 'whatsapp_mobile_number', 'text')} required={false} />
                                <label data-label="Whatsapp Mobile Number" className="form-label"></label>
                            </div>
                        </fieldset>
                    }
                    {validationForFields.alternative_number.includes(user_detail.role_details.role) &&
                        <fieldset className="col-md-4">
                            <div className="material">
                            <InputField onBlur={checkExisting} type="number" error={(errors.alternative_number) ? errors.alternative_number : ''} maxLength={12} name="alternative_number" id="alternative_number" label="Alternative Number" placeholder=" " value={basic_details.alternative_number} onChange={handleInputChange.bind(null, 'alternative_number', 'text')} required={false} />
                                <label data-label="Alternative Number" className="form-label"></label>
                            </div>
                        </fieldset>
                    }
            </div>
            <h3>Bank Details</h3>
            <div className="row">
                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator: () => null }}
                            options={bankList}
                            value={bankList.filter((e) => e.value === basic_details.bank_id)}
                            onChange={handleInputChange.bind(null, 'bank_id', "select")}
                            placeholder="Bank Name*"
                            className="react-select"
                            classNamePrefix="react-select"
                            isDisabled={ adminRoleId === ADMIN_ROLES['super_admin'] ? false : (user_detail && user_detail.basic_details && user_detail.basic_details.bank_account_number && user_detail.basic_details.beneficiary_name) ? !enable_bank_info : false}
                        />
                    </div>
                    {
                        (errors && errors.bank_id) ? <span className="error show">{errors.bank_id}</span> : ''
                    }
                </fieldset>

                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (user_detail && user_detail.basic_details && user_detail.basic_details.bank_account_number && user_detail.basic_details.beneficiary_name) ? !enable_bank_info : false} type="text" error={(errors.bank_branch_name) ? errors.bank_branch_name : ''} name="bank_branch_name" id="bank_branch_name" label="Branch" placeholder=" " value={basic_details.bank_branch_name} onChange={handleInputChange.bind(null, 'bank_branch_name', 'text')} required={true} />
                        <label data-label="Branch*" className="form-label"></label>
                    </div>
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (user_detail && user_detail.basic_details && user_detail.basic_details.bank_account_number && user_detail.basic_details.beneficiary_name) ? !enable_bank_info : false} onBlur={checkExisting} type="password" error={(errors.bank_account_number) ? errors.bank_account_number : ''} name="bank_account_number" id="bank_account_number" label="Account Number" placeholder=" " value={basic_details.bank_account_number} onChange={handleInputChange.bind(null, 'bank_account_number', 'text')} required={true} maxLength={account_length} RestrictOnPaste={1} RestrictOnCopy={1} />
                        <label data-label="Account Number*" className="form-label"></label>
                    </div>
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (user_detail && user_detail.basic_details && user_detail.basic_details.bank_account_number && user_detail.basic_details.beneficiary_name) ? !enable_bank_info : false} onBlur={checkExisting} type="number" error={(errors.confirm_bank_account_number) ? errors.confirm_bank_account_number : ''} name="confirm_bank_account_number" id="confirm_bank_account_number" label="Confirm Account Number" placeholder=" " value={basic_details.confirm_bank_account_number} onChange={handleInputChange.bind(null, 'confirm_bank_account_number', 'text')} required={true} maxLength={account_length} RestrictOnPaste={1} RestrictOnCopy={1} />
                        <label data-label="Confirm Account Number*" className="form-label"></label>
                    </div>
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField disabled={adminRoleId === ADMIN_ROLES['super_admin'] ? false : (user_detail && user_detail.basic_details && user_detail.basic_details.bank_account_number && user_detail.basic_details.beneficiary_name) ? !enable_bank_info : false} type="text" error={(errors.beneficiary_name) ? errors.beneficiary_name : ''} name="beneficiary_name" id="beneficiary_name" label="Beneficiary Name" placeholder=" " value={basic_details.beneficiary_name} onChange={handleInputChange.bind(null, 'beneficiary_name', 'text')} required={true} />
                        <label data-label="Beneficiary Name*" className="form-label"></label>
                    </div>
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <NumberFormat
                            className="form-input"
                            format="##.###.###.#-###.###"
                            mask="_"
                            id="npwp"
                            name="npwp"
                            label="NPWP"
                            // disabled={basic_details.npwp_disabled ? basic_details.npwp_disabled : false}
                            placeholder="NPWP"
                            value={basic_details.npwp || ''}
                            onBlur={checkExisting}
                            onValueChange={handleInputChange.bind(null, 'npwp', 'number')}
                        />
                        <label data-label="NPWP" className="form-label"></label>
                    </div>
                    {
                        (errors && errors.npwp) ? <span className="error show">{errors.npwp}</span> : ''
                    }
                </fieldset>
            </div>

            <h3>Other Details</h3>
            <div className="row">
                <fieldset className="col-md-4">
                    <div className="material">
                        <DOBDate
                            value={basic_details.date_of_birth}
                            handleDateChange={handleDateChange}
                        />
                        {(basic_details.date_of_birth) ? <label data-label="DOB (YYYY-MM-DD)*" className="form-label date-label"></label> : ''}
                    </div>
                    {
                        (errors && errors.date_of_birth) ? <span className="error show">{errors.date_of_birth}</span> : ''
                    }
                </fieldset>
                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator: () => null }}
                            options={stateList}
                            value={stateList.filter((e) => e.value === basic_details.state_name)}
                            onChange={handleInputChange.bind(null, 'state_name', "select")}
                            placeholder="State*"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                    {
                        (errors && errors.state_name) ? <span className="error show">{errors.state_name}</span> : ''
                    }
                </fieldset>
                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator: () => null }}
                            options={cityList}
                            value={cityList.filter((e) => e.value === basic_details.city)}
                            onChange={handleInputChange.bind(null, 'city', "select")}
                            placeholder="City*"
                            className="react-select"
                            isDisabled={basic_details.state_name && basic_details.state_name !== "" ? false : true}
                            classNamePrefix="react-select"
                        />
                    </div>
                    {
                        (errors && errors.city) ? <span className="error show">{errors.city}</span> : ''
                    }
                </fieldset>
                <fieldset className="single-select col-md-4">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            // components={{ IndicatorSeparator: () => null }}
                            options={localityList}
                            value={localityList.findIndex(e => e.value === basic_details.user_location) > -1 ? localityList.filter((e) => e.value === basic_details.user_location) : ""}
                            onChange={handleInputChange.bind(null, 'user_location', "select")}
                            placeholder="Locality"
                            className="react-select"
                            isDisabled={basic_details.city && basic_details.city !== "" ? false : true}
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
                {validationForFields.current_occupation.includes(user_detail.role_details?.role) &&
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator: () => null }}
                                options={ConfigList.current_occupation}
                                value={ConfigList.current_occupation.filter((e) => e.value === basic_details.current_occupation)}
                                onChange={handleInputChange.bind(null, 'current_occupation', "select")}
                                placeholder="Current Occupation*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.current_occupation) ? <span className="error show">{errors.current_occupation}</span> : ''
                        }
                    </fieldset>
                }

                {validationForFields.designation.includes(user_detail.role_details?.role) &&
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator: () => null }}
                                options={designationList}
                                value={designationList.findIndex(e => e.value === basic_details.designation) > -1 ? designationList.filter((e) => e.value === basic_details.designation) : ""}
                                onChange={handleInputChange.bind(null, 'designation', "select")}
                                placeholder="Designation*"
                                isDisabled={basic_details.current_occupation && basic_details.current_occupation !== "" ? false : true}
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.designation) ? <span className="error show">{errors.designation}</span> : ''
                        }
                    </fieldset>
                }
                {validationForFields.tagging.includes(user_detail.role_details?.role) && ConfigList && ConfigList.tagging_roles && ConfigList.tagging_roles.length > 0 &&
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator: () => null }}
                                options={ConfigList.tagging_roles}
                                value={ConfigList.tagging_roles.filter((e) => e.value === basic_details.tagging_id)}
                                onChange={handleInputChange.bind(null, 'tagging_id', "select")}
                                placeholder="Tagging*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.tagging_id) ? <span className="error show">{errors.tagging_id}</span> : ''
                        }
                    </fieldset>
                }
                {basic_details.tagging_id == "eca" &&
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator: () => null }}
                                options={agentReferralList}
                                value={basic_details?.eca_agent && Object.keys(basic_details.eca_agent) ? { value: basic_details.eca_agent.user_id, label: basic_details.eca_agent.name } : {}}
                                onChange={handleInputChange.bind(null, 'eca_agent', "select")}
                                placeholder="Referral Agent*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.eca_agent) ? <span className="error show">{errors.eca_agent}</span> : ''
                        }
                    </fieldset>
                }
                {/* {ConfigList && ConfigList.business_line && ConfigList.business_line.length > 0 &&
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator: () => null }}
                                options={ConfigList.business_line}
                                value={ConfigList.business_line.filter((e) => e.value === basic_details.business_line)}
                                onChange={handleInputChange.bind(null, 'business_line', "select")}
                                placeholder="Business Line*"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.business_line) ? <span className="error show">{errors.business_line}</span> : ''
                        }
                    </fieldset>
                } */}
                {validationForFields.source.includes(user_detail.role_details.role) &&
                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <Select components={{ ValueContainer: CustomValueContainer }}
                                    options={sources}
                                    value={sources.filter((e) => e.value === basic_details.source)}
                                    onChange={handleInputChange.bind(null, 'source', "select")}
                                    placeholder="Source*"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                            {
                                (errors && errors.source) ? <span className="error show">{errors.source}</span> : ''
                            }
                        </fieldset>
                    }
                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField type="number" error={(errors.postal_code) ? errors.postal_code : ''} name="postal_code" id="postal_code" label="Postal Code" placeholder=" " value={basic_details.postal_code} onChange={handleInputChange.bind(null, 'postal_code', 'text')} required={true} />
                        <label data-label="Postal Code*" className="form-label"></label>

                    </div>
                </fieldset>

                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField type="number" error={(errors.rt) ? errors.rt : ''} name="rt" id="rt" label={validationForFields.rt.includes(user_detail.role_details?.role) ? "RT*" : "RT"} placeholder=" " value={basic_details.rt} onChange={handleInputChange.bind(null, 'rt', 'text')} required={true} />
                        <label data-label={validationForFields.rt.includes(user_detail.role_details?.role) ? "RT*" : "RT"} className="form-label"></label>

                    </div>
                </fieldset>
                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField type="number" error={(errors.rw) ? errors.rw : ''} name="rw" id="rw" label={validationForFields.rw.includes(user_detail.role_details?.role) ? "RW*" : "RW"} placeholder=" " value={basic_details.rw} onChange={handleInputChange.bind(null, 'rw', 'text')} required={true} />
                        <label data-label={validationForFields.rw.includes(user_detail.role_details?.role) ? "RW*" : "RW"} className="form-label"></label>

                    </div>
                </fieldset>


                <fieldset className="col-md-4">
                    <div className="material">
                        <InputField type="text" error={(errors.house_number) ? errors.house_number : ''} name="house_number" id="house_number" label="House No." placeholder=" " value={basic_details.house_number} onChange={handleInputChange.bind(null, 'house_number', 'text')} required={true} />
                        <label data-label="House No." className="form-label"></label>
                    </div>
                </fieldset>
                {validationForFields.incentive_percentage.includes(user_detail.role_details?.role) &&
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="number" min="0" max="100" error={(errors.incentive_percentage) ? errors.incentive_percentage : ''} name="address" id="incentive_percentage" label="Incentive%" placeholder=" " value={basic_details.incentive_percentage} onChange={handleInputChange.bind(null, 'incentive_percentage', 'text')} required={true} />
                            <label data-label="Incentive%" className="form-label"></label>
                        </div>
                    </fieldset>
                }
                <fieldset className="col-md-8">
                    <div className="material">
                        <InputField type="text" error={(errors.address) ? errors.address : ''} name="address" id="address" label="Street Address" placeholder=" " value={basic_details.address} onChange={handleInputChange.bind(null, 'address', 'text')} required={true} />
                        <label data-label="Street Address *" className="form-label"></label>
                    </div>
                </fieldset>
                {validationForFields.is_tax_booster_enable.includes(user_detail.role_details?.role) &&
                    <fieldset className="col-md-4">
                        <div className="material toggle_outer">
                            <span className='m-md-r booster-text'>Tax Booster</span>
                            <ToggleSwitch toggleChecked={basic_details.is_tax_booster_enable ? basic_details.is_tax_booster_enable : false} onClick={handleInputChange.bind(null, 'is_tax_booster_enable', 'toggle')} />
                            {/* <label data-label="Tax Booster Enable" className="form-label"></label> */}
                        </div>
                    </fieldset>
                }
                {validationForFields.bm_grading.includes(user_detail.role_details?.role) &&
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator: () => null }}
                                options={ConfigList.bm_grading}
                                value={ConfigList.bm_grading.findIndex((e) => e.value === basic_details.bm_grading) > -1 ? ConfigList.bm_grading.filter((e) => e.value === basic_details.bm_grading) : []}
                                onChange={handleInputChange.bind(null, 'bm_grading', "select")}
                                placeholder={(TENANT=="id" || TENANT=="tri-id") ? "BM Grading" : "MM Grading" }
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                        {
                            (errors && errors.bm_grading) ? <span className="error show">{errors.bm_grading}</span> : ''
                        }
                    </fieldset>
                }
                {validationForFields.company_name.includes(user_detail.role_details?.role) &&
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.company_name) ? errors.company_name : ''} name="company_name" id="company_name" label="Company Name" placeholder=" " value={basic_details.company_name} onChange={handleInputChange.bind(null, 'company_name', 'text')} required={true} />
                            <label data-label="Company Name" className="form-label"></label>
                        </div>
                    </fieldset>
                }
                {validationForFields.company_address.includes(user_detail.role_details?.role) &&
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.company_address) ? errors.company_address : ''} name="company_address" id="company_address" label="Company Address" placeholder=" " value={basic_details.company_address} onChange={handleInputChange.bind(null, 'company_address', 'text')} required={false} />
                            <label data-label="Company Address" className="form-label"></label>
                        </div>
                    </fieldset>
                }

            </div>

            {is_edit && <h3>Additional Details</h3>}
            {is_edit &&
                <div className="row">


                    <fieldset className="col-md-4">
                        <div className="material">
                            <Calender3
                                value={basic_details.created_date}
                                text={"Created Date"}
                                name={"created_date"}
                                disabled={true}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.created_date) ? <label data-label="Created Date" className="form-label date-label"></label> : ''}
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <Calender3
                                value={basic_details.approval_date}
                                text={"Approval Date"}
                                name={"approval_date"}
                                disabled={true}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.approval_date) ? <label data-label="Approval Date" className="form-label date-label"></label> : ''}
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.approved_by_admin_name) ? errors.approved_by_admin_name : ''} name="approved_by_admin_name" id="approved_by_admin_name" label="Approved By" placeholder=" " value={basic_details.approved_by_admin_name?basic_details.approved_by_admin_name:""} required={true} disabled={true} />
                            <label data-label="Approved By" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.onboarded_from) ? errors.onboarded_from : ''} name="onboarded_from" id="onboarded_from" label="Onboarded From" placeholder=" " value={basic_details.onboarded_from} onChange={handleInputChange.bind(null, 'onboarded_from', 'text')} required={true} disabled={true} />
                            <label data-label="Onboarded From" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.onboarded_by) ? errors.onboarded_by : ''} name="house_number" id="onboarded_by" label="Onboarded By" placeholder=" " value={basic_details.onboarded_by} onChange={handleInputChange.bind(null, 'onboarded_by', 'text')} required={true} disabled={true} />
                            <label data-label="Onboarded By" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <Calender3
                                value={basic_details.resign_date_1}
                                text={"Resign Date 1"}
                                name={"resign_date_1"}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.resign_date_1) ? <label data-label="Resign Date 1" className="form-label date-label"></label> : ''}
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <Calender3
                                value={basic_details.resign_date_2}
                                text={"Resign Date 2"}
                                name={"resign_date_2"}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.resign_date_2) ? <label data-label="Resign Date 2" className="form-label date-label"></label> : ''}
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <Calender3
                                value={basic_details.rejoin_date_1}
                                text={"Rejoin Date 1"}
                                name={"rejoin_date_1"}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.rejoin_date_1) ? <label data-label="Rejoin Date 1" className="form-label date-label"></label> : ''}
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <Calender3
                                value={basic_details.rejoin_date_2}
                                text={"Rejoin Date 2"}
                                name={"rejoin_date_2"}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.rejoin_date_2) ? <label data-label="Rejoin Date 2" className="form-label date-label"></label> : ''}
                        </div>
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator: () => null }}
                                options={BasicDetailConfig.hiring_source}
                                value={BasicDetailConfig.hiring_source.filter((e) => e.value === basic_details.hiring_source)}
                                onChange={handleInputChange.bind(null, 'hiring_source', "select")}
                                placeholder="Hiring Source"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="col-md-8">
                        <div className="material">
                            <InputField type="text" error={(errors.comments) ? errors.comments : ''} name="comments" id="comments" label="Comments" placeholder=" " value={basic_details.comments} onChange={handleInputChange.bind(null, 'comments', 'text')} required={true} />
                            <label data-label="Comments" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.inactive_by_name) ? errors.inactive_by_name : ''} name="inactive_by_name" id="inactive_by_name" label="Inactivated By" placeholder=" " value={basic_details.inactive_by_name?basic_details.inactive_by_name:""} required={true} disabled={true} />
                            <label data-label="Inactivated By" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <Calender3
                                value={basic_details.inactive_date}
                                text={"Inactive Date"}
                                name={"inactive_date"}
                                disabled={true}
                                handleDateChange={handleDateChange}
                            />
                            {(basic_details.inactive_date) ? <label data-label="Inactive Date" className="form-label date-label"></label> : ''}
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.manager_status) ? errors.manager_status : ''} name="manager_status" id="manager_status" label="Manager Status" placeholder=" " value={basic_details.manager_status?basic_details.manager_status:""} required={true} disabled={true} />
                            <label data-label="Manager Status" className="form-label"></label>
                        </div>
                    </fieldset>
                </div>
            }

            {(TENANT=="id") &&
                <div>
                    <h3>Dealer Inventory Funding</h3>
                    <div className="row">

                        <fieldset className="col-md-6">
                            <li className="custom-control custom-checkbox">
                                <span>Dealer Inventory Funding Subscribe:</span>
                                <input
                                    id={`is_subscribe_dif`}
                                    type="checkbox"
                                    name="is_subscribe_dif"
                                    onChange={handleInputChange.bind(null, 'is_subscribe_dif', 'check')}
                                    className="custom-control-input"
                                    checked={basic_details.is_subscribe_dif ? true : false}
                                />

                                <label htmlFor={`is_subscribe_dif`} className="custom-control-label">
                                    {/* Sales Wallet */}
                                </label>
                            </li>
                        </fieldset>
                    </div>
                </div>
            }
            {/* <div>
                    <h3 style={{display:"flex"}}>Set Password
                    {is_edit && <span onClick={() => setEnableNewPassword(!enable_new_password)} className="enable_password">{enable_new_password?"Enable New Password":"Disable New Password"}</span>}
                    </h3>
                </div> */}

            {/* <div className="row">

                    <fieldset className="col-md-4">
                        <div className="material info-icn">
                            {password_type === "text" &&
                                <InputField disabled={enable_new_password} type="text" error={(errors.password) ? errors.password : ''} name="password" id="password" label="Password" placeholder=" " value={show_password_value?basic_details.password:""} onChange={handleInputChange.bind(null, 'password', 'text')} required={true} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            }
                            {password_type === "password" &&
                                <InputField disabled={enable_new_password} type="password" error={(errors.password) ? errors.password : ''} name="password" id="password" label="Password" placeholder=" " value={show_password_value?basic_details.password:""} onChange={handleInputChange.bind(null, 'password', 'text')} required={true} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            }
                            <label data-label="Password*" className="form-label"></label>
                            <span className="ic-showhide-password" onClick={() => changePasswordType(password_type)}><i className="ic-remove_red_eyevisibility" ></i></span>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material info-icn">
                            {confirm_password_type === "text" &&
                                <InputField disabled={enable_new_password} type="text" error={(errors.confirm_password) ? errors.confirm_password : ''} name="confirm_password" id="confirm_password" label="Confirm Password" placeholder=" " value={show_confirm_password_value?basic_details.confirm_password:""} onChange={handleInputChange.bind(null, 'confirm_password', 'text')} required={true} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            }
                            {confirm_password_type === "password" &&
                                <InputField disabled={enable_new_password} type="password" error={(errors.confirm_password) ? errors.confirm_password : ''} name="confirm_password" id="confirm_password" label="Confirm Password" placeholder=" " value={show_confirm_password_value?basic_details.confirm_password:""} onChange={handleInputChange.bind(null, 'confirm_password', 'text')} required={true} RestrictOnPaste={1} RestrictOnCopy={1}/>
                            }
                            <label data-label="Confirm Password*" className="form-label"></label>
                            <span className="ic-showhide-password" onClick={() => changeConfirmPasswordType(confirm_password_type)}><i className="ic-remove_red_eyevisibility" ></i></span>

                        </div>
                    </fieldset>
                </div> */}
            {validationForFields.referral_name.includes(user_detail.role_details?.role) &&
                <div>
                    <h3>Reference</h3>
                    <div className="row">

                        <fieldset className="single-select col-md-4">
                            <div className="material">
                                <AsyncSelect
                                    components={{ ValueContainer: CustomValueContainer }}
                                    onChange={handleInputChange.bind(null, 'referral_name', "select")}
                                    value={ReferralName.filter((e) => e.value === basic_details.referral_name)}
                                    loadOptions={e => promiseOptions(e)}
                                    // placeholder={"Start Typing..."}
                                    placeholder={"Referral Name & ID"}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />

                            </div>
                            <span className="message show">Type Atleast 3 Character</span>
                            <br />
                            {
                                (errors && errors.referral_name) ? <span className="error show">{errors.referral_name}</span> : ''
                            }
                        </fieldset>
                    </div>
                </div>
            }
            <div className='br-top-flex'>
                <div className="t-right">
                    {user_detail.status === "1" &&
                        <button className='btn-line m-xs-l' onClick={handleClick.bind(this, true)}>
                            Save And Skip
                        </button>
                    }
                </div>
                {is_edit ? <div className="t-right">
                    <button className='btn-line m-xs-l' onClick={handleClickCancel.bind(this)}>
                        Cancel
                    </button>
                </div> : null}
                <div className="t-right">
                    <button className='btn-primary' onClick={handleClick.bind(this, false)}>
                        Next
                    </button>
                </div>
            </div>

        </div>
    )
    // }
}
export default BasicDetails;