import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Zone, BasicDetailConfig, Role, validationForFields, defaultZone, EmploymentType, TENANT } from '../../config/constant';
import MasterService from '../../services/MasterService';
import DateFormat from 'dateformat';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import HierarchyDetails from './HierarchyPopUp';
import HierarchyIcon from "../../webroot/images/hierarchy_icon.png";
import AgentHierarchyDetails from './AgentHierarchyDetails';

const ViewUserProfile = (props) => {
    let { user_id } = useParams()
    // let { stateList, cityList } = props
    const [loading, setLoading] = useState(false);
    const [cityList, setCityList] = useState([])
    const [localityList, setLocalityList] = useState([])
    const [stateList, setStateList] = useState([])
    const [userDetail, setUserDetail] = useState({})
    const [roleList, setRoleDetails] = useState([])
    const [activeClass, setActiveClass] = useState(false)
    const [reportingRoleList, setReportingRoleList] = useState([])
    const [ReferralName, setReferralName] = useState([])
    const [ConfigList, setConfigList] = useState([])
    const [reportingManagerList, setReportingManagerList] = useState([])
    const [bankList, setBankList] = useState([])
    // const [bankOption, setBankOption] = useState([])//removed warning
    const [userLocationDetail, setUserLocationDetail] = useState({})
    const [showHierarchyDetailsModal, setShowHierarchyDetailsModal] = useState(false);
    const [showAgentHierarchyECA, setShowAgentHierarchyECA] = useState(false);
    // const [esignApprovedHTML, setEsignApprovedHTML] = useState([]);//removed warning
    const [esignUserDoc, setEsignUserDoc] = useState([]);
    const [commitmentForm, setCommitmentForm] = useState([]);
    const [terminationForm, setTerminationForm] = useState([]);
    // const [passwordToShow,setPasswordToShow]= useState('');//removed warning
    const [designationList, setDesignationList] = useState([])
    const [sources, setSources] = useState([]);

    // const [showPassword,setShowPassword]= useState(false);

    const historyLead = useNavigate();

    useEffect(() => {
        window.addEventListener('scroll', () => {
            let activeClass = 'sticky-top';
            if (window.scrollY === 0) {
                activeClass = 'top';
            }
            setActiveClass(activeClass)
        });
        getStateList();
        getCityList();
        getUserDetail(user_id);
        getUserLocationDetail(user_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     if (userDetail?.password_text) {
    //         if (showPassword) {
    //             setPasswordToShow(userDetail.password_text)
    //         } else {
    //             setPasswordToShow(userDetail.password_text.replace(/./g, '*'))
    //         }
    //     }

    // }, [showPassword, userDetail])

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async () => {
        MasterService.post('/sfa/user/city_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id }));
                    setCityList(city_list);
                }
            })
            .catch(function (response) {
            });
    }
    const getUserLocationDetail = async (user_id) => {
        await MasterService.post('/sfa/user/get-user_location', { user_id }).then(function (response) {
            if (response.data.status === 200) {
                let data = response.data.data
                let tempuUserDetail = { ...userLocationDetail };
                let state_data = data.location_response.map(({ state_id }) => state_id)
                state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);

                let city_data = data.location_response.map(({ city_id }) => city_id)
                city_data = city_data.filter((item, i, ar) => ar.indexOf(item) === i);
                tempuUserDetail.state_data = state_data
                tempuUserDetail.city_data = city_data
                setUserLocationDetail(tempuUserDetail)
            }
        })
    }

    const getSources = async (role_id) => {
        try {
            let response = await MasterService.post('/sfa/user/get_sources_config', { role_id: role_id});
            if (response && response.data && response.data.status === 200) {
                if (response.data.data) {
                    setSources(response.data.data);
                }
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const getUserDetail = async (user_id) => {
        setLoading(true)
        await MasterService.post('/sfa/user/get-view-detail', { user_id }).then(async function (response) {
            if (response.data.status === 200 && response.data.data) {
                getReferralUserList()
                getRoleList(response.data.data)
                let config_list = await getConfigList(response.data.data)
                getReportingRole(response.data.data)
                getUsersByRole(response.data.data)
                getBankList(response.data.data)
                getSources(response.data.data.role_id);
                let data = response.data.data

                //let state_data = data.location_response.map(({ state_id }) => state_id)
                //state_data = state_data.filter((item, i, ar) => ar.indexOf(item) === i);

                //let city_data = data.location_response.map(({ city_id }) => city_id)
                //city_data = city_data.filter((item, i, ar) => ar.indexOf(item) === i);
                //data.state_data = state_data
                //data.city_data = city_data

                data.bank_document = data.document_response.filter(e => parseInt(e.document_id) === 3 || parseInt(e.document_id) === 4)
                data.ktp_document = data.document_response.filter(e => parseInt(e.document_id) === 1 || parseInt(e.document_id) === 2)
                MasterService.getLocalityList(data.user_city).then((res) => {
                    let locality_list = res.data.data.map(elm => ({ id: elm.id, label: elm.value, value: elm.id }));
                    setLocalityList(locality_list)
                })

                let occupation_id_data = config_list.current_occupation.filter(e => e.value === userDetail.current_occupation_id)
                let occupation_id = occupation_id_data && occupation_id_data.length ? occupation_id_data[0].id : ""
                if (occupation_id) {
                    await MasterService.post('/sfa/user/designation-list', { occupation_id })
                        .then(function (response) {
                            if (response.data.status === 200) {
                                var designation_list = response.data.data.map(elm => ({ id: elm.value, label: elm.label, value: elm.value }));
                                setDesignationList(designation_list);
                            }
                        })
                        .catch(function (response) {
                        });
                }

                setUserDetail(data)
                // let tempEsignApprovedHTML = response?.data?.data?.esignHTMLData ? response?.data?.data?.esignHTMLData : [];//removed warning
                let tempEsignApprovedDoc = response?.data?.data?.esignUserDoc ? response?.data?.data?.esignUserDoc : [];
                let tempCommitmentForm = response?.data?.data?.commitmentForm ? response?.data?.data?.commitmentForm : [];
                let tempTerminationForm = response?.data?.data?.terminationForm ? response?.data?.data?.terminationForm : [];
                // setEsignApprovedHTML(tempEsignApprovedHTML);//removed warning
                setEsignUserDoc(tempEsignApprovedDoc)
                setCommitmentForm(tempCommitmentForm);
                setTerminationForm(tempTerminationForm);
                setLoading(false)
            } else {
                if (response.data.status === 400 && response.data.message){
                    toast.error(response.data.message)
                } else {
                    toast.error("Invalid request")
                }
                historyLead("/user-management-detail")
            }
        })
    }

    const handleDownload = async (ele) => {
        setLoading(true);
        let pdfData =  await MasterService.download('/sfa/user/download_esign', {user_id : ele.user_id_hash}, 'arraybuffer')
        const blob = new Blob([pdfData.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Esign_Agreement.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    }

     const handleDownloadCommitmentForm = async (ele) => {
        setLoading(true);
        let pdfData =  await MasterService.download('/sfa/user/download_commitment_form', {user_id : ele.user_id_hash}, 'arraybuffer')
        const blob = new Blob([pdfData.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Employee_Commitment_Form.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    }

    const handleDownloadTerminationForm = async (ele) => {
        setLoading(true);
        let pdfData =  await MasterService.download('/sfa/user/download_termination_form', {user_id : ele.user_id_hash}, 'arraybuffer')
        const blob = new Blob([pdfData.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Termination_Form.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    }

    const getReferralUserList = async () => {
        MasterService.post('/sfa/user/get_referral_user', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var referral_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name + " (" + elm.id + ")", value: elm.id }));
                    setReferralName(referral_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getBankList = async (data) => {
        MasterService.post('/sfa/user/bank-list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var bank_list = response.data.data.filter(elm => elm.bankId === data.bank_response.sfa_bank_id);
                    bank_list = bank_list.map(elm => ({ id: elm.bankId, label: elm.bankName, value: elm.bankId }));
                    // var bank_list_option = response.data.data.map(elm => ({ id: elm.bankId, label: elm.bankName, value: elm.bankId }));//removed warning
                    // setBankOption(bank_list_option)//removed warning
                    setBankList(bank_list);
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async (data) => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data.filter(elm => elm.id === data.role_id);
                    setRoleDetails(role_list)
                }
            })
            .catch(function (response) {
            });
    }

    const getConfigList = async (data) => {
        let config_list 
        await MasterService.post('/sfa/user/get-config', { role_id: data.role_id, send_bm_grading: true })
            .then(function (response) {
                if (response.data.status === 200) {
                    setConfigList(response.data.data)
                    config_list = response.data.data
                }
            })
            .catch(function (response) {
            });
            return config_list
    }

    const getUsersByRole = async (data) => {
        if (data.reporting_response.length > 0) {
            MasterService.post('/sfa/user/get-users', { role_id: data.reporting_response[0].manager_role_id })
                .then(function (response) {
                    if (response.data.status === 200) {
                        let reporting_manager_list = response.data.data.user_list.filter(elm => parseInt(elm.id) === parseInt(data.reporting_response[0].manager_id));
                        setReportingManagerList(reporting_manager_list)
                    }
                })
                .catch(function (response) {
                });
        } else {
            setReportingManagerList([])
        }
    }
    const getReportingRole = async (data) => {
        if (data.reporting_response.length > 0) {
            MasterService.post('/sfa/user/reporting_role_list', { role_id: data.role_id })
                .then(function (response) {
                    if (response.data.status === 200) {
                        let reporting_role_list = response.data.data.filter(elm => elm.id === data.reporting_response[0].manager_role_id);
                        setReportingRoleList(reporting_role_list)
                    }
                })
                .catch(function (response) {
                });
        } else {
            setReportingRoleList([])
        }
    }

    const showHierarychyDetails = () => {       
        setShowHierarchyDetailsModal(true);
        document.body.classList.add("overflow-hidden");
    }
    const hideHierarychyDetails = () => {        
        setShowHierarchyDetailsModal(false);
        document.body.classList.remove("overflow-hidden");
    }

    const showAgentHierarchyECAPopup = () => {
        setShowAgentHierarchyECA(true)
        document.body.classList.add("overflow-hidden");
    }

    const hideAgentHierarchyECAPopup = () => {
        setShowAgentHierarchyECA(false)
        document.body.classList.add("overflow-hidden");
    }

    return (
        <React.Fragment>
            <div className='container-fluid'>
                {loading ? <Loader /> : null}
                <div className={`top-bar ${activeClass}`}>
                    <div className="top-heading d-flex">
                        <div><h1>{userDetail?.name}</h1><span>({roleList[0]?.name})</span></div>
                        <div className="right-btn btn-agent-detail">
                            <div className="t-right text-right">
                                <button className='btn-line m-xs-l' onClick= {()=>historyLead(-1)}>
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='agent-detail-profile'>
                    <div className='role-detail-preview preview-confirmation'>
                        <div className='profile-photo'>
                            {userDetail.profile_image_path !== "" ?
                                <img src={userDetail.profile_image_path} className="" alt="no pic" /> :
                                <label>No Image</label>
                            }
                        </div>

                    </div>
                    <div className='role-detail-preview d-flex'>
                        <ul>
                            <li>
                                <span>Username</span>
                                <label>{userDetail.name}</label>
                            </li>
                            <li>
                                <span>Status</span>
                                <label>{(userDetail.status === '0') ? 'In-active' : (userDetail.status == '1') ? 'Active' : (userDetail.status == '3') ? 'Rejected' : (userDetail.status == '4') ? 'In-process' : ''}</label>
                            </li>
                            {(userDetail.role_id == Role.Agent) && <li>
                                <span>Current Level</span>
                                <label>{(userDetail.agent_level) ? userDetail.agent_level : 'NA'}</label>
                            </li>
                            }
                            {!([Role.VP, Role.RBH].includes(userDetail?.role_id)) ? <li>
                                <span>Hierarchy</span>
                                <span className="truncate" title="">{userDetail.bm_name}</span>
                                <span onClick={showHierarychyDetails} title={"Show Hierarchy"}>
                                    <img src={HierarchyIcon} className="hierarchy-icon" alt="" />
                                </span>
                            </li> : null}

                            {([Role.Agent].includes(userDetail?.role_id)) && userDetail.eca_agent_hierarchy?.length ? <li>
                                <span>ECA Agent Hierarchy</span>
                                <span className="truncate" title="">{userDetail.bm_name}</span>
                                <span onClick={showAgentHierarchyECAPopup} title={"ECA Agent Hierarchy"}>
                                    <img src={HierarchyIcon} className="hierarchy-icon" alt="" />
                                </span>
                            </li> : null}


                            {
                                showHierarchyDetailsModal === false ? '' : (
                                    <div className='view-timeline-popup image-timeline-popup reporting-line-popup'>
                                        <Modal show={showHierarchyDetailsModal} handleClose={hideHierarychyDetails} style={{ width: "500px", borderRadius: "15px" }}>
                                            <HierarchyDetails allManagerList={userDetail.allManagerList} />
                                        </Modal>
                                    </div>
                                )
                            }
                            {
                                showAgentHierarchyECA === false ? '' : (
                                    <div className='view-timeline-popup image-timeline-popup reporting-line-popup'>
                                        <Modal show={showAgentHierarchyECA} handleClose={hideAgentHierarchyECAPopup} style={{ width: "500px", borderRadius: "15px" }}>
                                            <AgentHierarchyDetails agentHierarchy={userDetail.eca_agent_hierarchy} />
                                        </Modal>
                                    </div>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="agent-detail-tads">
                                <div className='preview-confirmation add-user-detail-filed'>
                                    <div className='top-heading d-flex'>
                                        <h2>Role Details</h2>
                                    </div>

                                    <div className='d-flex'>
                                        <div className='role-detail-preview'>
                                            <h3>Business Vertical</h3>
                                            <ul>
                                                <li>
                                                    <span>Business</span>
                                                    <label>{(TENANT=="tri-id") ? 'TriiVCO' :(TENANT=="fav-id")? 'Favorite':"OTO"}</label>
                                                </li>
                                                <li>
                                                    <span>Vertical</span>
                                                    <label>Used Car</label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='role-detail-preview'>
                                            <h3>Map Region</h3>
                                            <ul>
                                                <li>
                                                    <span>Zone</span>
                                                    <label>{Zone.filter(e => e.id === defaultZone)[0].label}</label>

                                                </li>
                                                {userLocationDetail.state_data &&
                                                    <li>
                                                        <span>State</span>
                                                        <label>{userLocationDetail.state_data ? userLocationDetail.state_data.length : "No"} States</label>
                                                    </li>
                                                }
                                                {userLocationDetail.city_data &&
                                                    <li>
                                                        <span>City</span>
                                                        <label>{userLocationDetail.city_data ? userLocationDetail.city_data.length : "No"} Cities</label>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='d-flex m-xl-t p-sm-t m-xl-b p-md-b preview-basic-details'>
                                        <div className='role-detail-preview'>
                                            <h3>Role and Reporting</h3>
                                            <ul>

                                                <li>
                                                    <span>Role</span>
                                                    {roleList && roleList.length > 0 ?
                                                        <label>{roleList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                {ConfigList.business_line &&
                                                    <li>
                                                        <span>Business Line</span>
                                                        <label>{ConfigList.business_line.findIndex(e => e.value === userDetail.business_line) > -1 ? ConfigList.business_line.filter(e => e.value === userDetail.business_line)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                <li>
                                                    <span>Reporting Role</span>
                                                    {reportingRoleList && reportingRoleList.length > 0 ?
                                                        <label>{reportingRoleList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Reporting Manager</span>
                                                    {reportingManagerList && reportingManagerList.length > 0 ?
                                                        <label>{reportingManagerList[0].name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className='top-heading d-flex'>
                                        <h2>Basic Details</h2>
                                    </div>

                                    <div className='d-flex'>

                                        <div className='role-detail-preview preview-basic-details'>
                                            <h3>Personal Detail</h3>
                                            <ul>
                                                <li>
                                                    <span>Username</span>
                                                    <label>{userDetail.name ? userDetail.name : "-"}</label>
                                                </li>
                                                <li>
                                                    <span >Mobile</span>
                                                    <label style={{ display: "flex" }}>{userDetail.mobile ? userDetail.mobile : "-"}
                                                        {userDetail.is_mobile_no_verified === "1" ?
                                                            <span className='verify-txt'>Verified</span> : null}
                                                    </label>
                                                </li>
                                                <li>
                                                    <span>Email</span>
                                                    <label title={userDetail.email ? userDetail.email : "-"}>{userDetail.email ? userDetail.email : "-"}</label>
                                                </li>

                                                <li>
                                                    <div>
                                                        <span>KTP ID</span>
                                                    </div>
                                                    <label style={{ display: "flex" }}>{userDetail.ktp_id ? userDetail.ktp_id : "-"}
                                                        </label>
                                                </li>
                                                <li>
                                                    <span>Employment Type</span>
                                                    <label>{EmploymentType.findIndex(e => e.value === userDetail.employe_type) > -1 ? EmploymentType.filter(e => e.value === userDetail.employe_type)[0].label : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>Employee ID</span>
                                                    <label title={userDetail.employe_id ? userDetail.employe_id : "-"}>{userDetail.employe_id ? userDetail.employe_id : "-"}</label>
                                                </li>

                                                <li>
                                                    <span>Joining Date</span>
                                                    {userDetail.doj && isNaN(Date.parse(userDetail.doj)) === false ?
                                                        <label>{new Date(userDetail.doj).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Anniversary</span>
                                                    {userDetail.anniversary_date && isNaN(Date.parse(userDetail.anniversary_date)) === false ?
                                                        <label>{new Date(userDetail.anniversary_date).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='d-flex  p-sm-t'>
                                        <div className='role-detail-preview preview-basic-details'>
                                            <div >
                                                <h3 style={{ display: "flex" }}>Bank Details
                                                    </h3>

                                            </div>
                                            <ul>
                                                <li>
                                                    <span>Bank Name</span>
                                                    {bankList.length > 0 ?
                                                        <label>{bankList[0].label}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>


                                                <li>
                                                    <span>Branch</span>
                                                    {userDetail.bank_response && userDetail.bank_response.branch_name ?
                                                        <label>{userDetail.bank_response.branch_name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Account Number</span>
                                                    {userDetail.bank_response && userDetail.bank_response.account_number ?
                                                        <label>{userDetail.bank_response.account_number}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Beneficiary Name</span>
                                                    {userDetail.bank_response && userDetail.bank_response.beneficiary_name ?
                                                        <label>{userDetail.bank_response.beneficiary_name}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>NPWP</span>
                                                    {userDetail.bank_response && userDetail.bank_response.NPWP ?
                                                        <label>{userDetail.bank_response.NPWP}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='d-flex  p-sm-t'>
                                        <div className='role-detail-preview preview-basic-details'>
                                            <h3>Other Details</h3>
                                            <ul>
                                                <li>
                                                    <span>DOB</span>
                                                    {userDetail.dob && isNaN(Date.parse(userDetail.dob)) === false ?
                                                        <label>{new Date(userDetail.dob).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>State</span>
                                                    <label>{stateList.findIndex(e => e.id === userDetail.user_state) > -1 ? stateList.filter(e => e.id === userDetail.user_state)[0].label : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>City</span>
                                                    <label>{cityList.findIndex(e => e.id === userDetail.user_city) > -1 ? cityList.filter(e => e.id === userDetail.user_city)[0].label : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>Locality</span>
                                                    <label>{localityList.findIndex(e => e.id === userDetail.user_location) > -1 ? localityList.filter(e => e.id === userDetail.user_location)[0].label : "-"}</label>
                                                </li>
                                                {validationForFields.current_occupation.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Current Occupation</span>
                                                        <label>{ConfigList.current_occupation.findIndex(e => e.value === userDetail.current_occupation_id) > -1 ? ConfigList.current_occupation.filter(e => e.value === userDetail.current_occupation_id)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                {validationForFields.designation.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Designation</span>
                                                        <label>{designationList.findIndex(e => e.value === userDetail.designation_id) > -1 ? designationList.filter(e => e.value === userDetail.designation_id)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                {ConfigList.tagging_roles && validationForFields.tagging.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Tagging</span>
                                                        <label>{ConfigList.tagging_roles.findIndex(e => e.value === userDetail.tagging_id) > -1 ? ConfigList.tagging_roles.filter(e => e.value === userDetail.tagging_id)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                
                                                {validationForFields.source.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Source</span>
                                                        <label>{sources.findIndex(e => e.value === userDetail.source_id) > -1 ? sources.filter(e => e.value === userDetail.source_id)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                <li>
                                                    <span>Postal Code</span>
                                                    <label>{userDetail.pincode ? userDetail.pincode : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>House No.</span>
                                                    <label>{userDetail.house_no ? userDetail.house_no : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>RT</span>
                                                    <label>{userDetail.rt ? userDetail.rt : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>RW</span>
                                                    <label>{userDetail.rw ? userDetail.rw : "-"}</label>
                                                </li>
                                                {validationForFields.incentive_percentage.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Incentive%</span>
                                                        {userDetail.incentive_percentage > 0 ?
                                                            <label>{userDetail.incentive_percentage}</label> :
                                                            <label>-</label>
                                                        }
                                                    </li>
                                                }
                                                <li className='address-txt'>
                                                    <span>Address</span>
                                                    <label>{userDetail.address ? userDetail.address : "-"}</label>
                                                </li>
                                                {validationForFields.is_tax_booster_enable.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Tax Booster Enable</span>
                                                        <label>{userDetail.is_tax_booster_enable == true ? "Yes" : "No"}</label>
                                                    </li>
                                                }
                                                {validationForFields.bm_grading.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>{(TENANT=="id" || TENANT=="tri-id") ? "BM Grading" : "MM Grading" }</span>
                                                        <label>{ConfigList.bm_grading.findIndex(e => e.value === userDetail.bm_grading) > -1 ? ConfigList.bm_grading.filter(e => e.value === userDetail.bm_grading)[0].label : "-"}</label>
                                                    </li>
                                                }
                                                {validationForFields.company_name.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Company Name</span>
                                                        <label title={userDetail.company_name ? userDetail.company_name : ""}>{userDetail.company_name ? userDetail.company_name : "-"}</label>
                                                    </li>
                                                }
                                                {validationForFields.company_address.includes(userDetail.role_id) &&
                                                    <li>
                                                        <span>Company Address</span>
                                                        <label title={userDetail.company_address ? userDetail.company_address : ""}>{userDetail.company_address ? userDetail.company_address : "-"}</label>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='d-flex  p-sm-t'>
                                        {/* {userDetail.password_text &&
                                            <div className='role-detail-preview preview-basic-details'>
                                                <h3>Password</h3>
                                                <ul>
                                                    <li>
                                                        <span>Password&nbsp;&nbsp;<i className="ic-remove_red_eyevisibility" onClick={() => setShowPassword(!showPassword)}></i></span>
                                                        <label>{passwordToShow}</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        } */}
                                        {validationForFields.referral_name.includes(userDetail.role_id) && ReferralName && ReferralName.length > 0 && userDetail.referral_user_id > 0 &&
                                            <div className='role-detail-preview'>
                                                <h3>Reference</h3>
                                                <ul>
                                                    <li>
                                                        <span>Reference Name & ID</span>
                                                        <label>{ReferralName.findIndex(e => e.value === userDetail.referral_user_id) > -1 ? ReferralName.filter(e => e.id === userDetail.referral_user_id)[0].label : ""}</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                    {(TENANT=="id") &&
                                    <div className='d-flex  p-sm-t m-xl-b p-md-b'>
                                        <div className='role-detail-preview'>
                                            <h3>Dealer Inventory Funding</h3>
                                            <li className="custom-control custom-checkbox">
                                                <span>Dealer Inventory Funding Subscribe: </span>
                                                <input
                                                    id={`is_subscribe_dif`}
                                                    type="checkbox"
                                                    name="is_subscribe_dif"
                                                    className="custom-control-input"
                                                    checked={userDetail.is_subscribe_dif ? true : false}
                                                />

                                                <label htmlFor={`is_subscribe_dif`} className="custom-control-label">
                                                </label>
                                            </li>
                                        </div>
                                    </div>
                                    }
                                    <div className='d-flex  p-sm-t m-xl-b p-md-b'>
                                        <div className='role-detail-preview preview-basic-details'>
                                            <h3>Additional Details</h3>
                                            <ul>
                                                {userDetail.created_date && isNaN(Date.parse(userDetail.created_date)) === false &&
                                                    <li>
                                                        <span>Created Date</span>
                                                        <label>{new Date(userDetail.created_date).toISOString().split('T')[0]}</label>
                                                    </li>
                                                }
                                                {userDetail.approval_date && isNaN(Date.parse(userDetail.approval_date)) === false &&
                                                    <li>
                                                        <span>Approval Date</span>
                                                        <label>{new Date(userDetail.approval_date).toISOString().split('T')[0]}</label>
                                                    </li>
                                                }
                                                <li>
                                                    <span>Onboarded From</span>
                                                    <label>{userDetail.user_source ? userDetail.user_source : "-"}</label>
                                                </li>
                                                <li>
                                                    <span>Onboarded By</span>
                                                    <label>{(userDetail.user_source && userDetail.user_source=='admin') ? userDetail.created_by_admin_name : userDetail.created_by_user_name}</label>
                                                </li>
                                                <li>
                                                    <span>Resign Date 1</span>
                                                    {userDetail.resign_date_1 && isNaN(Date.parse(userDetail.resign_date_1)) === false ?
                                                        <label>{new Date(userDetail.resign_date_1).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Resign Date 2</span>
                                                    {userDetail.resign_date_2 && isNaN(Date.parse(userDetail.resign_date_2)) === false ?
                                                        <label>{new Date(userDetail.resign_date_2).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Rejoin Date 1</span>
                                                    {userDetail.rejoin_date_1 && isNaN(Date.parse(userDetail.rejoin_date_1)) === false ?
                                                        <label>{new Date(userDetail.rejoin_date_1).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>
                                                <li>
                                                    <span>Rejoin Date 2</span>
                                                    {userDetail.rejoin_date_2 && isNaN(Date.parse(userDetail.rejoin_date_2)) === false ?
                                                        <label>{new Date(userDetail.rejoin_date_2).toISOString().split('T')[0]}</label> :
                                                        <label>-</label>
                                                    }
                                                </li>

                                                <li>
                                                    <span>Hiring Source</span>
                                                    <label>{BasicDetailConfig.hiring_source.findIndex(e => e.value === userDetail.hiring_source) > -1 ? BasicDetailConfig.hiring_source.filter(e => e.value === userDetail.hiring_source)[0].label : "-"}</label>
                                                </li>

                                                <li className='address-txt'>
                                                    <span>Comments</span>
                                                    <label>{userDetail.comments ? userDetail.comments : "-"}</label>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    
                                    {ConfigList && ConfigList.document && ConfigList.document.length > 0 && userDetail.document_response && userDetail.document_response.length > 0 &&
                                        < div className='top-heading d-flex'>
                                            <h2>Documents</h2>
                                            
                                        </div>
                                    }

                                    <div className='d-flex'>
                                        <div className='role-detail-preview doc-preview-details'>

                                            <div className='upload-document-outer'>
                                                {ConfigList && ConfigList.document && ConfigList.document.length > 0 && userDetail.document_response && userDetail.document_response.length > 0 && userDetail.document_response.map((data, index) => (
                                                    <div key={index} className="img-bx-select-opt">
                                                        <div className="image-bx">
                                                            {data.doc_url !== ""?
                                                            <img src={data.doc_url} className="" alt="no_image" />:
                                                            null}
                                                        </div>
                                                        <span className='document-name'>{ConfigList.document.filter(e => parseInt(e.id) === data.document_id)[0].doc_title}</span>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>

                                    {/* {esignApprovedHTML?.length ? <>
                                        <div className='top-heading d-flex'>
                                            <h2>Esign Agreement</h2>
                                        </div>
                                        <div className='d-flex' style={{ marginBottom: "15px" }}>
                                            <div className='role-detail-preview' >
                                                {esignApprovedHTML.map((ele) => {
                                                    let encodedID = Buffer.from(`${ele.id}`).toString('base64');
                                                    return (
                                                        <div className='row' style={{ marginBottom: "5px" }}>
                                                            <label class="col-sm-12">{`Esign Link\t:\t`}<a href={`${ESIGN_BASE_URL}?id=${encodedID}`} title="Document" target="_blank" rel="noreferrer">View ({DateFormat(ele.created_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </> : null} */}
                                    
                                    {esignUserDoc?.length ? 
                                    <>
                                        <div className='top-heading d-flex'>
                                            <h2>Esign Agreement</h2>
                                        </div>
                                        
                                        {esignUserDoc?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {esignUserDoc.map((ele) => {
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Esign Doc\t:\t`}<a href={() => false} onClick={() => { handleDownload(ele) }} title="Document" rel="noopener noreferrer">View ({DateFormat(ele.esign_approval_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null}
                                    </> : null}

                                    {commitmentForm?.length ? 
                                    <>
                                        <div className='top-heading d-flex'>
                                            <h2>Employee Commitment Form</h2>
                                        </div>
                                        
                                        {commitmentForm?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {commitmentForm.map((ele) => {
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Employee Commitment Form\t:\t`}<a href={() => false} onClick={() => { handleDownloadCommitmentForm(ele) }} title="Document" rel="noopener noreferrer">View ({DateFormat(ele.commitment_approval_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null}
                                    </> : null}

                                    {terminationForm?.length ? 
                                    <>
                                        <div className='top-heading d-flex'>
                                            <h2>Termination Form</h2>
                                        </div>
                                        
                                        {terminationForm?.length ? (
                                            <div className='d-flex' style={{ marginBottom: "15px" }}>
                                                <div className='role-detail-preview'>
                                                    {terminationForm.map((ele) => {
                                                        return (
                                                            <div className='row' style={{ marginBottom: "5px" }}>
                                                                <label class="col-sm-12">{`Termination Form\t:\t`}<a href={() => false} onClick={() => { handleDownloadTerminationForm(ele) }} title="Document" rel="noopener noreferrer">View ({DateFormat(ele.termination_approval_date, "yyyy-mm-dd hh:MM TT")})</a></label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : null}
                                    </> : null}
                                </div>
                </div>
            </div>

        </React.Fragment>
    )
}
const Modal = ({ handleClose, show, children, style = {} }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main' style={style}>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};
export default ViewUserProfile;