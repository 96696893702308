import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import MasterService from './../../services/MasterService';
import { BusinessLine, Role, TENANT, validationForFields } from './../../config/constant';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
// import secureStorage from '../../config/encrypt';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};
const ChangeMappingPopUp = (props) => {
    const [reportingRole, setReportingRole] = useState([]);
    const [reportingTo, setReportingTo] = useState([]);
    const [errors, setErrors] = useState({});
    const [reportingRoleId, setReportingRoleId] = useState(0);
    const [reportingUserId, setReportingUserId] = useState(0);
    const [errorList, setErrorList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mappingList, setMappingList] = useState([]);
    const [secondaryMappingList, setSecondaryMappingList] = useState([]);
    const [currentUserRoleId, setCurrentUserRoleId] = useState(undefined);
    const [secondaryReporting, setSecondaryReporting] = useState(false);
    const [secondaryReportingRoleId, setSecondaryReportingRoleId] = useState(0);
    const [secondaryReportingUserId, setSecondaryReportingUserId] = useState(0);
    const [secondaryReportingTo, setSecondaryReportingTo] = useState([]);
    const [selectedBusinessLine, setBusinessLine] = useState("")
    const [selectedRoleId, setRole] = useState("")
    useEffect(() => {
        setReportingRole(props.ReportingRole || []);
    }, [props.ReportingRole])
    useEffect(() => {
        let tempMappingList = [...mappingList];
        let tempSecondaryMappingList = [...secondaryMappingList];
        if (props?.userList?.user_list?.length) {
            tempMappingList = props.userList.user_list.filter(ele => ele.is_selected);
            tempSecondaryMappingList = props.userList.user_list.filter(ele => ele.is_selected);
            let selectedRoles = tempMappingList.map(item => item.role_id);
            let tempCurrentUserRoleId = 0;
            if (selectedRoles && selectedRoles.length === 1) {
                tempCurrentUserRoleId = tempMappingList[0]?.role_id;
            } else if (selectedRoles && selectedRoles.length > 1) {
                if (selectedRoles.includes(Role['ARO'])) {
                    tempCurrentUserRoleId = Role['ARO'];
                } else if (selectedRoles.includes(Role['BRO'])) {
                    tempCurrentUserRoleId = Role['BRO'];
                } else {
                    tempCurrentUserRoleId = tempMappingList[0]?.role_id;
                }
            }
            setCurrentUserRoleId(tempCurrentUserRoleId);
            tempMappingList = tempMappingList.map((ele) => {
                return {
                    user_id: ele.userId,
                    manager_id: reportingUserId,
                    manager_role_id: reportingRoleId
                }
            })
            tempSecondaryMappingList = tempSecondaryMappingList.map((ele) => {
                return {
                    user_id: ele.userId,
                    manager_id: secondaryReportingUserId,
                    manager_role_id: secondaryReportingRoleId
                }
            })
        }
        setMappingList(tempMappingList);
        setSecondaryMappingList(tempSecondaryMappingList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.userList, reportingUserId, reportingRoleId, secondaryReportingUserId, secondaryReportingRoleId])
    useEffect(() => {
        if(props.RoleData && props.RoleData.length){
            let role_id = props.RoleData[0].id
            setRole(role_id)
        }
        if (props.currentChangeManagerRole && props.currentBusinessLine) {
            let user_business_line = BusinessLine.findIndex(e=>e.label === props.currentBusinessLine) > -1 ? BusinessLine.filter(e=>e.label === props.currentBusinessLine)[0].value : ""
            setBusinessLine(user_business_line)
            
            setReportingRoleId(Role[props.currentChangeManagerRole]);
            getRoleUsersList(Role[props.currentChangeManagerRole], props.currentBusinessLine);
        }
    }, [props.currentChangeManagerRole])
    useEffect(() => {
        if (currentUserRoleId) {
            getReportingRole(currentUserRoleId)
        }
    }, [currentUserRoleId])
    const getReportingRole = async (role_id) => {
        MasterService.post('/sfa/user/reporting_role_list', { role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_role_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name, value: elm.name, name: elm.name }));
                    if(currentUserRoleId === Role['Agent'] && props.currentBusinessLine === 'Used Car Finance'){
                        reporting_role_list = reporting_role_list.filter(item => item.id !== Role['ARO']);  
                    }
                    setReportingRole(reporting_role_list)
                }
            })
            .catch(function (response) {
            });
    }
    const handleReportingRoleChange = (e) => {
        setReportingRoleId(e.id);
        getRoleUsersList(e.id, props.currentBusinessLine);
        setErrorList([]);
        let tempErrors = { errors };
        delete tempErrors['reporting_to_role']
        setErrors(tempErrors);
    }

    const handleSecondaryReportingRoleChange = (e) => {
        setSecondaryReportingRoleId(e.id);
        getSecondaryRoleUsersList(e.id, selectedBusinessLine);
        setErrorList([]);
        let tempErrors = { errors };
        delete tempErrors['secondary_reporting_to_role']
        setErrors(tempErrors);
    }

    const handleReportingToChange = (e) => {
        setReportingUserId(e.id);
        setErrorList([]);
        let tempErrors = { errors };
        delete tempErrors['reporting_to']
        setErrors(tempErrors);
    }

    const handleSecondaryReportingToChange = (e) => {
        setSecondaryReportingUserId(e.id);
        setErrorList([]);
        let tempErrors = { errors };
        delete tempErrors['secondary_reporting_to']
        setErrors(tempErrors);
    }

    const getRoleUsersList = (role_id, business_line) => {
        setLoading(true);
        MasterService.post('/sfa/user/get-users', { 'role_id': role_id, 'business_line': business_line })
            .then(function (response) {
                if (response.data.status == 200) {
                    let responseData = response.data.data.user_list.map(item => {
                        return {
                            ...item, 
                            name: item.business_line ? `${item.name} (${item.business_line})` : item.name
                        }
                    });
                    setReportingTo(responseData);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(function (response) {
                setLoading(false);
            });
    }

    const getSecondaryRoleUsersList = (role_id, business_line) => {
        setLoading(true);
        MasterService.post('/sfa/user/get-users', { 'role_id': role_id })
            .then(function (response) {
                if (response.data.status == 200) {
                    let secondary_reporting_manager_list = response.data.data.user_list
                    secondary_reporting_manager_list = secondary_reporting_manager_list.filter(e => e.business_line !== business_line)
                    secondary_reporting_manager_list = secondary_reporting_manager_list.map(item => {
                        return {
                            ...item,
                            name: item.business_line ? `${item.name} (${item.business_line})` : item.name
                        }
                    })
                    setSecondaryReportingTo(secondary_reporting_manager_list);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(function (response) {
                setLoading(false);
            });
    }

    const validateForm = () => {
        let result = true;
        let tempErrors = { errors };
        if(secondaryReporting){
            if (!secondaryReportingRoleId) {
                tempErrors['secondary_reporting_to_role'] = 'Please select Secondary Reporting To Role';
                result = false;
            }
            if (!secondaryReportingUserId) {
                tempErrors['secondary_reporting_to'] = 'Please select Secondary Reporting To user';
                result = false;
            }
        } else {
            if (!reportingRoleId) {
                tempErrors['reporting_to_role'] = 'Please select Reporting To Role';
                result = false;
            }
            if (!reportingUserId) {
                tempErrors['reporting_to'] = 'Please select Reporting To user';
                result = false;
            }
        }
        setErrors(tempErrors);
        if (result) {
            if (!mappingList.length) {
                result = false;
                toast.error("No User Selected")
            } else {
                // let currentUserList = props?.userList?.user_list || [];//remove warning
                // mappingList.forEach((ele)=>{
                //     let currentUser = currentUserList.find( u => u.userId == ele.user_id);
                //     if(currentUser){
                //         if(Role[currentUser.reporting_to_role] && Role[currentUser.reporting_to_role] != ele.manager_role_id){
                //           errorList.push(ele)
                //         }
                //     }
                // })
                if (errorList.length) {
                    result = false;
                    toast.error(`User's manager type can not be updated.`)
                }
            }
        }
        return result;
    };
    const handleChangeMappingSubmit = (e) => {
        setErrors({});
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            let params = { "mapping_list": mappingList, is_secondary: 0 }
            if(secondaryReporting){
                params.mapping_list = secondaryMappingList
                params.is_secondary = 1
            }
            // let logged_data = localStorage.getItem("logged_data")?JSON.parse(localStorage.getItem("logged_data")):{}
            // if(logged_data.id){
            //     params.userId = logged_data.id
            // }
            MasterService.post('/sfa/user/bulk_mapping_update', params)
                .then(function (response) {
                    if (response.data.status == 200) {
                        setLoading(false);
                        toast.success(response.data.message);
                    } else {
                        setLoading(false);
                        toast.error(response.data.message);
                    }
                })
                .catch(function (response) {
                    setLoading(false);
                    toast.error(response.data.message);
                });
        }
    }

    const handleInputChange = async (target_name, type, event) => {
        if(target_name === 'secondary_hierarchy'){
            if(secondaryReporting){
                setSecondaryReportingRoleId(0)
                setSecondaryReportingUserId(0)
            }
        } else {
            setReportingRoleId(0)
            setReportingUserId(0)
        }
        setSecondaryReporting(!secondaryReporting)
    }
    return (<>
        {loading ? <Loader /> : null}
        <form method="post" onSubmit={handleChangeMappingSubmit} style={{ marginTop: "10px" }}>
            <div className="row" style={{ height: "100%" }}>
                <fieldset className="single-select col-md-12 d-flex hierarchy-div">
                    <li className="custom-control custom-checkbox">
                        <input
                            id={`primary_hierarchy`}
                            type="checkbox"
                            name="primary_hierarchy"
                            className="custom-control-input"
                            onChange={handleInputChange.bind(null, 'primary_hierarchy', 'check')}
                            checked={!secondaryReporting ? true : false}
                        />
                        <label htmlFor={`primary_hierarchy`} className="custom-control-label">
                        </label>
                        <span>Primary Hierarchy</span>
                    </li>
                    {(TENANT=="id" || TENANT=="tri-id") && validationForFields.secondary_reporting_div.includes(parseInt(selectedRoleId)) &&
                        validationForFields.secondary_reporting_div_business_line.includes(selectedBusinessLine) &&
                        <li className="custom-control custom-checkbox secondary-div">
                            <input
                                id={`secondary_hierarchy`}
                                type="checkbox"
                                name="secondary_hierarchy"
                                className="custom-control-input"
                                onChange={handleInputChange.bind(null, 'secondary_hierarchy', 'check')}
                                checked={secondaryReporting ? true : false}
                            />
                            <label htmlFor={`secondary_hierarchy`} className="custom-control-label">
                            </label>
                            <span>Secondary Hierarchy</span>
                        </li>
                    }
                </fieldset>
                {secondaryReporting === false &&
                    <fieldset className="single-select col-md-6">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                id="reporting_role_id"
                                name="reporting_role_id"
                                onChange={handleReportingRoleChange}
                                options={reportingRole}
                                value={reportingRole.filter(({ id }) => id === reportingRoleId)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                placeholder="Reporting to Role"
                                className="react-select"
                                classNamePrefix="react-select"
                            // isDisabled={props.currentChangeManagerRole ? true : false}
                            />
                            {
                                (errors && errors.reporting_to_role) ? <span className="error show">{errors.reporting_to_role}</span> : ''
                            }
                        </div>
                    </fieldset>
                }
                {secondaryReporting === false &&
                    <fieldset className="single-select col-md-6">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                // components={{ IndicatorSeparator:() => null }}
                                id="reporting_to"
                                name="reporting_to"
                                onChange={handleReportingToChange}
                                options={reportingTo}
                                value={reportingTo.filter(({ id }) => id === reportingUserId)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                placeholder="Reporting To User"
                                className="react-select"
                                classNamePrefix="react-select"
                                isDisabled={reportingRoleId ? false : true}
                            />
                            {
                                (errors && errors.reporting_to) ? <span className="error show">{errors.reporting_to}</span> : ''
                            }
                        </div>
                    </fieldset>
                }
                {(TENANT=="id" || TENANT=="tri-id") && secondaryReporting === true &&
                    <fieldset className="single-select col-md-6">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                id="secondary_reporting_role_id"
                                name="secondary_reporting_role_id"
                                onChange={handleSecondaryReportingRoleChange}
                                options={reportingRole}
                                value={reportingRole.filter(({ id }) => id === secondaryReportingRoleId)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                placeholder="Secondary Reporting to Role"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                            {
                                (errors && errors.secondary_reporting_to_role) ? <span className="error show">{errors.secondary_reporting_to_role}</span> : ''
                            }
                        </div>
                    </fieldset>
                }
                {(TENANT=="id" || TENANT=="tri-id") && secondaryReporting === true &&
                    <fieldset className="single-select col-md-6">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                id="secondary_reporting_to"
                                name="secondary_reporting_to"
                                onChange={handleSecondaryReportingToChange}
                                options={secondaryReportingTo}
                                value={secondaryReportingTo.filter(({ id }) => id === secondaryReportingUserId)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                placeholder="Secondary Reporting To User"
                                className="react-select"
                                classNamePrefix="react-select"
                                isDisabled={secondaryReportingRoleId ? false : true}
                            />
                            {
                                (errors && errors.secondary_reporting_to) ? <span className="error show">{errors.secondary_reporting_to}</span> : ''
                            }
                        </div>
                    </fieldset>
                }
            </div>
            <div className='row' style={{ marginBottom: "10px", marginTop: "50px" }}>
                <div className="col-md-12 text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <button type="submit" className="btn-primary text-center">Update</button>
                </div>
            </div>
        </form>
    </>);
}
export default ChangeMappingPopUp;