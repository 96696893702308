import React, { useEffect, useRef, useState } from 'react';
import LeadListFilter from "./LeadListTopFilter";
import { Tab, Nav } from 'react-bootstrap';
import UserDetailTable from "./UserDetailTable";
import MasterService from '../../services/MasterService';
import { toast } from 'react-toastify';
import { Role, RoleId } from '../../config/constant';
import Loader from '../common/loader';
import { CSVLink } from "react-csv";
import WithRights from '../common/WithRights';


const ProgressionDashboard = () => {
    const csvLink = useRef();
    const hierarchyLink = useRef();

    let [dashboardUserData, setDashboardUserData] = useState([]);
    let [dashboardDeviation, setDashboardDeviation] = useState([]);
    let [dashboardCsvData, setDashboardCsvData] = useState([]);
    let [filterData, setFilterData] = useState({});
    let [errors, setErrors] = useState({});
    let [currentRoleId, setCurrentRoleId] = useState(Role["BM"]);
    let [subRoleNameOne, setSubRoleNameOne] = useState('');
    let [loading, setLoading] = useState(false);
    let [progressionHierarchyCsv, setProgressionHierarchyCsv] = useState([]);
    let [exportPopup, setExportPopup] = useState(false);
    let [tableShow, setTabelshow] = useState('');

    const searchWithFilters = (event, params) => {
        if (isValidForm()) {
            event.preventDefault();
            getProgressionDashboard(params);
        }
    }

    useEffect(() => {
        getProgressionDashboard(filterData)
        getProgressionHierarchyData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoleId])

    function formatCurrency(num, delimiter) {
        if (num) {
            let numStr = num.toString();
            return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
        }
        return num;
    }

    const getProgressionDashboard = async (params) => {
        try {
            setLoading(true);
            params.user_role_id = currentRoleId;
            params.page_no = 1;
            let response = await MasterService.post("/sfa/user/get_progression_dashboard", params);
            if (response.data.status === 200) {
                if (response.data.data) {
                    let response_data = response.data.data;
                    setDashboardUserData(response_data.dashboard_data);
                    setDashboardDeviation(response_data.deviation_data)

                    let csvData = []
                    if (parseInt(currentRoleId) == Role.BM) {
                        csvData = response_data.dashboard_data.map((obj) => ({
                            'SFA ID': obj.main_id,
                            'User Name': obj.main_name ? obj.main_name : "",
                            'User Count': obj.user_reportee_count ? obj.user_reportee_count : 0,
                            'Status': obj.main_status == 1 ? "Approved" : "Pending",
                            'System Deviation': obj.system_deviation === "NULL" ? 0 : formatCurrency(obj.system_deviation, ','),
                            'BM Deviation': obj.recommended_deviation === "NULL" ? 0 : formatCurrency(obj.recommended_deviation, ',')
                        }))
                    }

                    if (currentRoleId == Role.AM) {
                        csvData = response_data.dashboard_data.map((obj) => ({
                            'SFA ID': obj.main_id,
                            'User Name': obj.main_name ? obj.main_name : "",
                            'User Count': obj.user_reportee_count ? obj.user_reportee_count : 0,
                            'Status': obj.main_status == 1 ? "Approved" : "Pending",
                            'System Deviation': obj.system_deviation === "NULL" ? 0 : formatCurrency(obj.system_deviation, ','),
                            'AM Deviation': obj.recommended_deviation === "NULL" ? 0 : formatCurrency(obj.recommended_deviation, ',')
                        }))
                    }

                    if (currentRoleId == Role.ZM) {
                        csvData = response_data.dashboard_data.map((obj) => ({
                            'SFA ID': obj.main_id,
                            'User Name': obj.main_name ? obj.main_name : "",
                            'User Count': obj.user_reportee_count ? obj.user_reportee_count : 0,
                            'Status': obj.main_status == 1 ? "Approved" : "Pending",
                            'System Deviation': obj.system_deviation === "NULL" ? 0 : formatCurrency(obj.system_deviation, ','),
                            'ZM Deviation': obj.recommended_deviation === "NULL" ? 0 : formatCurrency(obj.recommended_deviation, ',')
                        }))
                    }

                    if (currentRoleId == Role.RBH) {
                        csvData = response_data.dashboard_data.map((obj) => ({
                            'SFA ID': obj.main_id,
                            'User Name': obj.main_name ? obj.main_name : "",
                            'User Count': obj.user_reportee_count ? obj.user_reportee_count : 0,
                            'Status': obj.main_status == 1 ? "Approved" : "Pending",
                            'System Deviation': obj.system_deviation === "NULL" ? 0 : formatCurrency(obj.system_deviation, ','),
                            'RBH Deviation': obj.recommended_deviation === "NULL" ? 0 : formatCurrency(obj.recommended_deviation, ',')
                        }))
                    }

                    setDashboardCsvData(csvData);
                    setLoading(false);
                } else {
                    setDashboardUserData([]);
                    setDashboardDeviation([]);
                    setDashboardCsvData([]);
                    setLoading(false);
                }
            } else {
                toast.error(response.data.message);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error);
            setLoading(false);
        }
    }

    const getProgressionHierarchyData = async () => {
        setLoading(true);
        try {
            let response = await MasterService.post("/sfa/user/progression_hierarchy_data", {});
            if (response?.data?.status === 200) {
                let responseData = response?.data?.data ?? [];
                let csvData = [];
                for (const data of responseData) {
                    csvData.push({
                        'SFA ID': data.user_id,
                        'Username': data.name ? data.name : "",
                        'Last Month Role': data.current_role_id ? data.current_role_id : "",
                        'Last Month tagging': data.current_tagging_id ? data.current_tagging_id : "",
                        'System Recommended Role': data.sys_recommended_role_id ? data.sys_recommended_role_id : "",
                        'System recommended tagging': data.sys_recommended_tagging_id ? data.sys_recommended_tagging_id : "",
                        'BM Recommended Role': data.bm_recommended_role_id ? data.bm_recommended_role_id : "",
                        'BM Recommended Tagging': data.bm_recommended_tagging_id ? data.bm_recommended_tagging_id : "",
                        'Final Role': data.recommended_role_id ? data.recommended_role_id : "",
                        'Final Tagging': data.recommended_tagging_id ? data.recommended_tagging_id : "",
                        'Final manager (BM)': data.bm_name ? data.bm_name : "",
                        'Points': data.points ? data.points : 0
                    });
                }
                setProgressionHierarchyCsv(csvData);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error)
        } finally {
            setLoading(false);
        }
    }

    const handleCurrentRoleId = (role_id) => {
        setTabelshow("")
        getSubRoleNameOne(role_id);
        setCurrentRoleId(role_id);
    }

    const getSubRoleNameOne = (currentRoleId) => {
        if (currentRoleId === Role['AM']) {
            setSubRoleNameOne(RoleId[Role['BM']])
        } else if (currentRoleId === Role['ZM']) {
            setSubRoleNameOne(RoleId[Role['AM']])
        } else if (currentRoleId === Role['RBH']) {
            setSubRoleNameOne(`${RoleId[Role['AM']]}/${RoleId[Role['ZM']]}`)
        } else {
            setSubRoleNameOne('');
        }
    }

    const isValidForm = () => {
        let isFormValid = true;
        let tempFilterData = { ...filterData };
        let tempErrors = { ...errors };
        if (tempFilterData['reporting_role_id'] && !tempFilterData['reporting_manager']) {
            isFormValid = false;
            tempErrors['reporting_manager'] = 'Please Select Manager'
        }
        setErrors(tempErrors);
        return isFormValid;
    }

    const downloadProgressionData = () => {
        if (dashboardCsvData && dashboardCsvData.length > 0) {
            csvLink.current.link.click();
        }
        hideExportPopup();
    }

    const downloadHierarchyData = () => {
        if (progressionHierarchyCsv && progressionHierarchyCsv.length > 0) {
            hierarchyLink.current.link.click();
        }
        hideExportPopup();
    }

    const hideExportPopup = () => {
        setExportPopup(false);
    }

    const showExportPopup = () => {
        setExportPopup(true);
    }

    return (
        <>
            {loading ? <Loader /> : null}
            <div className="Progression-user-detail-outer">
                <div className="container-fluid">
                    <div className="top-heading">
                        <h1>Journey Dashboard</h1>
                    </div>
                    <div className="loan-filter-bg dashboard-filter-top">
                        <LeadListFilter
                            searchWithFilters={searchWithFilters}
                            filterData={filterData}
                            setFilterData={setFilterData}
                            getProgressionDashboard={getProgressionDashboard}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    </div>
                    <div className="sub-heading p-lg-t p-lg-b">
                        <h2>User Details</h2>
                        <div className="report-heading-right-panel">
                            <WithRights rightKey="export_data">
                                <button className="btn-line btn-export m-sm-l" disabled={dashboardCsvData && dashboardCsvData.length > 0 ? false : true}>
                                    <i className="ic-export"></i>
                                    <span onClick={currentRoleId !== Role["BM"] ? downloadProgressionData : showExportPopup}>Export Data</span>
                                    <CSVLink
                                        data={dashboardCsvData}
                                        filename={`progression-list-${Math.round(+new Date() / 1000)}.csv`}
                                        data-interception='off'
                                        ref={csvLink}
                                    >
                                    </CSVLink>
                                </button>
                            </WithRights>
                        </div>
                    </div>
                    <div className="payout-summary-tabs">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="pills" className="flex-column tab-line">
                                <div className="tab-list">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first" onClick={() => { handleCurrentRoleId(Role["BM"]) }}>BM Level</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second" onClick={() => { handleCurrentRoleId(Role["AM"]) }}>AM Level</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third" onClick={() => { handleCurrentRoleId(Role["ZM"]) }}>ZM Level</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth" onClick={() => { handleCurrentRoleId(Role["RBH"]) }}>RBH Level</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className='payout-summery-right-panel'>
                                        <ul>
                                            <li>
                                                <span className='total-payout-txt'> {formatCurrency(dashboardDeviation.total_system_deviation, '.')}</span>
                                                <label>System Deviation</label>
                                            </li>
                                            <li>
                                                <span className='total-incentives-txt'> {formatCurrency(dashboardDeviation.total_recommended_deviation, '.')}</span>
                                                <label>{`${RoleId[currentRoleId]} Deviation`}</label>
                                            </li>
                                            <li>
                                                <span className='total-incentives-txt'> {formatCurrency(dashboardDeviation.deviation_percentage, '.')}</span>
                                                <label>{`${RoleId[currentRoleId]} Deviation %`}</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <UserDetailTable dashboardUserData={dashboardUserData} currentRoleId={currentRoleId} subRoleNameOne={subRoleNameOne} tableShow={tableShow} filterData={filterData} getProgressionDashboard={getProgressionDashboard} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className='payout-summery-right-panel'>
                                        <ul>
                                            <li>
                                                <span className='total-payout-txt'> {formatCurrency(dashboardDeviation.total_system_deviation, '.')}</span>
                                                <label>System Deviation</label>
                                            </li>
                                            <li>
                                                <span className='total-incentives-txt'> {formatCurrency(dashboardDeviation.total_recommended_deviation, '.')}</span>
                                                <label>{`${RoleId[currentRoleId]} Deviation`}</label>
                                            </li>
                                            <li>
                                                <span className='total-incentives-txt'> {formatCurrency(dashboardDeviation.deviation_percentage, '.')}</span>
                                                <label>{`${RoleId[currentRoleId]} Deviation %`}</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <UserDetailTable dashboardUserData={dashboardUserData} currentRoleId={currentRoleId} subRoleNameOne={subRoleNameOne} tableShow={tableShow} filterData={filterData} getProgressionDashboard={getProgressionDashboard} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className='payout-summery-right-panel'>
                                        <ul>
                                            <li>
                                                <span className='total-payout-txt'> {formatCurrency(dashboardDeviation.total_system_deviation, '.')}</span>
                                                <label>System Deviation</label>
                                            </li>
                                            <li>
                                                <span className='total-incentives-txt'> {formatCurrency(dashboardDeviation.total_recommended_deviation, '.')}</span>
                                                <label>{`${RoleId[currentRoleId]} Deviation`}</label>
                                            </li>
                                            <li>
                                                <span className='total-incentives-txt'> {formatCurrency(dashboardDeviation.deviation_percentage, '.')}</span>
                                                <label>{`${RoleId[currentRoleId]} Deviation %`}</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <UserDetailTable dashboardUserData={dashboardUserData} currentRoleId={currentRoleId} subRoleNameOne={subRoleNameOne} tableShow={tableShow} filterData={filterData} getProgressionDashboard={getProgressionDashboard} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="fourth">
                                    <div className='payout-summery-right-panel'>
                                        <ul>
                                            <li>
                                                <span className='total-payout-txt'> {formatCurrency(dashboardDeviation.total_system_deviation, '.')}</span>
                                                <label>System Deviation</label>
                                            </li>
                                            <li>
                                                <span className='total-incentives-txt'> {formatCurrency(dashboardDeviation.total_recommended_deviation, '.')}</span>
                                                <label>{`${RoleId[currentRoleId]} Deviation`}</label>
                                            </li>
                                            <li>
                                                <span className='total-incentives-txt'> {formatCurrency(dashboardDeviation.deviation_percentage, '.')}</span>
                                                <label>{`${RoleId[currentRoleId]} Deviation %`}</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <UserDetailTable dashboardUserData={dashboardUserData} currentRoleId={currentRoleId} subRoleNameOne={subRoleNameOne} tableShow={tableShow} filterData={filterData} getProgressionDashboard={getProgressionDashboard} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
                <div className="vechile-document-popup model-popup-outer">
                    <Modal show={exportPopup} handleClose={hideExportPopup}>
                        <div className="modal-footer">
                            <div className="image-opt">
                                <button className="btn-primary m-md-r" disabled={dashboardCsvData && dashboardCsvData.length > 0 ? false : true}>
                                    <span onClick={downloadProgressionData}>BM Data</span>
                                    <CSVLink
                                        data={dashboardCsvData}
                                        filename={`progression-list-${Math.round(+new Date() / 1000)}.csv`}
                                        data-interception='off'
                                        ref={csvLink}
                                    >
                                    </CSVLink>
                                </button>
                                <button className="btn-primary m-md-l" disabled={progressionHierarchyCsv && progressionHierarchyCsv.length > 0 ? false : true}>
                                    <span onClick={downloadHierarchyData}>Hierarchy Data</span>
                                    <CSVLink
                                        data={progressionHierarchyCsv}
                                        filename={`progression-list-${Math.round(+new Date() / 1000)}.csv`}
                                        data-interception='off'
                                        ref={hierarchyLink}
                                    >
                                    </CSVLink>
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    );
};

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default ProgressionDashboard;
