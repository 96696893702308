import React, { useState, useEffect } from 'react';
import { Role, TENANT } from '../../config/constant';
import { Tab, Nav } from 'react-bootstrap';


const HierarchyDetails = (props) => {
  const [managersData, setManagersData] = useState({});
  const [secondaryManagersData, setSecondaryManagersData] = useState({});
  const [showSecondaryHierarchy, setShowSecondaryHierarchy] = useState(false);


  const handleSecondaryHierarchy = () => {
    setShowSecondaryHierarchy(true);
  }

  useEffect(() => {
  let tempManagersData = { ...managersData };
  let tempSecondaryManagers = { ...secondaryManagersData };
  if (props.allManagerList && props.allManagerList.length) {
    for (let data of props.allManagerList) {
      if (TENANT=="id" || TENANT=="tri-id") {
        if (data.role_id == Role.ARO) {
          tempManagersData['ARO'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.ARCO) {
          tempManagersData['ARCO'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.BM) {
          tempManagersData['BM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.AM) {
          tempManagersData['AM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.ZM) {
          tempManagersData['ZM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.RBH) {
          tempManagersData['RBH'] = {
            id: data.id,
            name: data.name,
          };
        }
      } else {
        if (data.role_id == Role.FA) {
          tempManagersData['FA'] = {
            id: data.id,
            name: data.name,
          };
        } 
        if (data.role_id == Role.MO) {
          tempManagersData['MO'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.MM) {
          tempManagersData['MM'] = {
            id: data.id,
            name: data.name,
          };
        }               
        if (data.role_id == Role.RM) {
          tempManagersData['RM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.GM) {
          tempManagersData['GM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.RBH) {
          tempManagersData['RBH'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.VP) {
          tempManagersData['VP'] = {
            id: data.id,
            name: data.name,
          };
        }
      }
    }
  }

  if (props.secondaryManagersList && props.secondaryManagersList.length) {
    for (let data of props.secondaryManagersList) {
      if (TENANT=="id" || TENANT=="tri-id") {
        if (data.role_id == Role.ARO) {
          tempSecondaryManagers['ARO'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.ARCO) {
          tempSecondaryManagers['ARCO'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.BM) {
          tempSecondaryManagers['BM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.AM) {
          tempSecondaryManagers['AM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.ZM) {
          tempSecondaryManagers['ZM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.RBH) {
          tempSecondaryManagers['RBH'] = {
            id: data.id,
            name: data.name,
          };
        }
      } else {
        if (data.role_id == Role.MM) {
          tempSecondaryManagers['MM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.FA) {
          tempSecondaryManagers['FA'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.MO) {
          tempSecondaryManagers['MO'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.RM) {
          tempSecondaryManagers['RM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.GM) {
          tempSecondaryManagers['GM'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.RBH) {
          tempSecondaryManagers['RBH'] = {
            id: data.id,
            name: data.name,
          };
        }
        if (data.role_id == Role.VP) {
          tempSecondaryManagers['VP'] = {
            id: data.id,
            name: data.name,
          };
        }
      }
    }
  }

  setSecondaryManagersData(tempSecondaryManagers);
  setManagersData(tempManagersData);
}, [props.allManagerList]);



  return (
    <div className="reporting-line-detail">
      <div className="modal-header">
        <h2>Reporting Line</h2>
        <span>
          {"User Details"}
        </span>
      </div>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="dealer-user-mapping-tabs">
          <Nav variant="pills" className="flex-column tab-line">
            <div className="tab-list">
              <Nav.Item>
                <Nav.Link eventKey="first" onClick={() => { }}>Primary Hierarchy</Nav.Link>
              </Nav.Item>
              {/* {props.secondaryManagersList && props.secondaryManagersList.length ? <Nav.Item>
                <Nav.Link eventKey="second" onClick={() => handleSecondaryHierarchy()}> Seconday Hierarchy </Nav.Link>
              </Nav.Item> : ""} */}
            </div>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey={"first"}>
            {props.allManagerList ? (
              <div className='modal-body'>
                {(TENANT=="id" || TENANT=="tri-id") ? (
                  <>
                    {managersData?.ARO?.id && (
                      <>
                        <div className="detail">
                          <div>{"ARO Name"}</div>
                          <div>{managersData?.ARO?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"ARO ID"}</div>
                          <div>{managersData?.ARO?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.ARCO?.id && (
                      <>
                        <div className="detail">
                          <div>{"ARCO Name"}</div>
                          <div>{managersData?.ARCO?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"ARCO ID"}</div>
                          <div>{managersData?.ARCO?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.BM?.id && (
                      <>
                        <div className="detail">
                          <div>{"BM Name"}</div>
                          <div>{managersData?.BM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"BM ID"}</div>
                          <div>{managersData?.BM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.AM?.id && (
                      <>
                        <div className="detail">
                          <div>{"AM Name"}</div>
                          <div>{managersData?.AM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"AM ID"}</div>
                          <div>{managersData?.AM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.ZM?.id && (
                      <>
                        <div className="detail">
                          <div>{"ZM Name"}</div>
                          <div>{managersData?.ZM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"ZM ID"}</div>
                          <div>{managersData?.ZM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.RBH?.id && (
                      <>
                        <div className="detail">
                          <div>{"RBH Name"}</div>
                          <div>{managersData?.RBH?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"RBH ID"}</div>
                          <div>{managersData?.RBH?.id || "-"}</div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                  {managersData?.MO?.id && (
                      <>
                        <div className="detail">
                          <div>{"MO Name"}</div>
                          <div>{managersData?.MO?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"MO ID"}</div>
                          <div>{managersData?.MO?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.MM?.id && (
                      <>
                        <div className="detail">
                          <div>{"MM Name"}</div>
                          <div>{managersData?.MM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"MM ID"}</div>
                          <div>{managersData?.MM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.FA?.id && (
                      <>
                        <div className="detail">
                          <div>{"FA Name"}</div>
                          <div>{managersData?.FA?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"FA ID"}</div>
                          <div>{managersData?.FA?.id || "-"}</div>
                        </div>
                      </>
                    )}                    
                    {managersData?.RM?.id && (
                      <>
                        <div className="detail">
                          <div>{"RM Name"}</div>
                          <div>{managersData?.RM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"RM ID"}</div>
                          <div>{managersData?.RM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.GM?.id && (
                      <>
                        <div className="detail">
                          <div>{"GM Name"}</div>
                          <div>{managersData?.GM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"GM ID"}</div>
                          <div>{managersData?.GM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {managersData?.RBH?.id && (
                      <>
                        <div className="detail">
                          <div>{"RBH Name"}</div>
                          <div>{managersData?.RBH?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"RBH ID"}</div>
                          <div>{managersData?.RBH?.id || "-"}</div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </Tab.Pane>
          <Tab.Pane eventKey={"second"}>
            {props.secondaryManagersList && showSecondaryHierarchy ? (
              <div className='modal-body'>
                {(TENANT=="id" || TENANT=="tri-id") ? (
                  <>
                    {secondaryManagersData?.ARO?.id && (
                      <>
                        <div className="detail">
                          <div>{"ARO Name"}</div>
                          <div>{secondaryManagersData?.ARO?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"ARO ID"}</div>
                          <div>{secondaryManagersData?.ARO?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.ARCO?.id && (
                      <>
                        <div className="detail">
                          <div>{"ARCO Name"}</div>
                          <div>{secondaryManagersData?.ARCO?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"ARCO ID"}</div>
                          <div>{secondaryManagersData?.ARCO?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.BM?.id && (
                      <>
                        <div className="detail">
                          <div>{"BM Name"}</div>
                          <div>{secondaryManagersData?.BM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"BM ID"}</div>
                          <div>{secondaryManagersData?.BM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.AM?.id && (
                      <>
                        <div className="detail">
                          <div>{"AM Name"}</div>
                          <div>{secondaryManagersData?.AM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"AM ID"}</div>
                          <div>{secondaryManagersData?.AM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.ZM?.id && (
                      <>
                        <div className="detail">
                          <div>{"ZM Name"}</div>
                          <div>{secondaryManagersData?.ZM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"ZM ID"}</div>
                          <div>{secondaryManagersData?.ZM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.RBH?.id && (
                      <>
                        <div className="detail">
                          <div>{"RBH Name"}</div>
                          <div>{secondaryManagersData?.RBH?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"RBH ID"}</div>
                          <div>{secondaryManagersData?.RBH?.id || "-"}</div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {secondaryManagersData?.MM?.id && (
                      <>
                        <div className="detail">
                          <div>{"MM Name"}</div>
                          <div>{secondaryManagersData?.MM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"MM ID"}</div>
                          <div>{secondaryManagersData?.MM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.FA?.id && (
                      <>
                        <div className="detail">
                          <div>{"FA Name"}</div>
                          <div>{secondaryManagersData?.FA?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"FA ID"}</div>
                          <div>{secondaryManagersData?.FA?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.MO?.id && (
                      <>
                        <div className="detail">
                          <div>{"MO Name"}</div>
                          <div>{secondaryManagersData?.MO?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"MO ID"}</div>
                          <div>{secondaryManagersData?.MO?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.RM?.id && (
                      <>
                        <div className="detail">
                          <div>{"RM Name"}</div>
                          <div>{secondaryManagersData?.RM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"RM ID"}</div>
                          <div>{secondaryManagersData?.RM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.GM?.id && (
                      <>
                        <div className="detail">
                          <div>{"GM Name"}</div>
                          <div>{secondaryManagersData?.GM?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"GM ID"}</div>
                          <div>{secondaryManagersData?.GM?.id || "-"}</div>
                        </div>
                      </>
                    )}
                    {secondaryManagersData?.RBH?.id && (
                      <>
                        <div className="detail">
                          <div>{"RBH Name"}</div>
                          <div>{secondaryManagersData?.RBH?.name || "-"}</div>
                        </div>
                        <div className="detail">
                          <div>{"RBH ID"}</div>
                          <div>{secondaryManagersData?.RBH?.id || "-"}</div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
}

export default HierarchyDetails;