import UserManagement from "../view/manage-user/UserManagement"
import UserManagementDetail from "../view/manage-user/UserManagementDetails"
import AddUser from "../view/manage-user/AddUser"
import EditUser from "../view/manage-user/EditUser"
import BasicDetailUpdated from "../view/manage-user/BasicDetailUpdated"
import DocumentUplaodPreview from "../view/manage-user/DocumentUplaodPreview"
import ViewUser from "../view/manage-user/ViewUser"
import AgentDetails from "../view/manage-user/AgentDetail"
import ViewUserProfile from "../view/manage-user/ViewUserProfile"
import logout from "../view/user-login/logout"
import DealerUserListing from "../view/dealer-user-listing/DealerUserListing"
import VisitReport from "../view/view-report/VisitReport"
import MapDealerManagement from "../view/map-dealers/MapDealer"
import OfficeAddress from "../view/admin/OfficeAddress"
import EditOfficeAddress from "../view/admin/EditOfficeAddress"
import ResetMaxAttempts from "../view/admin/ResetMaxAttempts"

import ESIGNLOGVIEW from "../view/esign/EsignView"
import NoVisitReport from "../view/no-view-report/NoVisitReport"

import progressionList from "../view/progression/progressionList"
import Login from "../view/login/Login"

import RoleManagement from "../view/manage-role/RoleManagement"
import AddRole from "../view/manage-role/AddRole"
import RoleUserDetail from "../view/manage-role/RoleUserDetail"
import EditRole from "../view/manage-role/EditRole.js"
import ProgressionDashboard from "../view/progression-dashboard/ProgressionDashboard"
import AdminUserManagement from "../view/admin/AdminUserManagement"
import AddAdminUser from "../view/admin/AddAdminUser"
import ViewAdminUser from "../view/admin/ViewAdminUser"
import EditAdminUser from "../view/admin/EditAdminUser"
import RemoveInfo from "../view/login/remove-info";
import PageNotFound from "../view/login/PageNotFound.js";
import CustomNotification from "../view/custom-notification/CustomNotification.js"
import CreateCustomNotification from "../view/custom-notification/CreateCustomNotification.js"
import ls from "local-storage";//removed warning
import SalesTarget from "../view/sales-target/SalesTarget.js"
import { TENANT } from "../config/constant.js"
const logged_Data = ls.get('logged_data');
let NavigationRoutes = [];
if(!logged_Data?.token){
NavigationRoutes = [
	{ path: "/remove-info", component: RemoveInfo },
	{ path: "/login", exact: true, component: Login },
	{ path: "/", exact: true, component: Login },
	{ path: "/*", component: PageNotFound },

	{ path: "/user-management",exact: true, component: Login },	
	{ path: "/user-management-detail", component: Login },
	{ path: "/basic-detail-updated", component: Login },
	{ path: "/document-preview", component: Login },
	{ path: "/agent-detail", component: Login },
	{ path: "/view-user/:user_id", component: Login },
	{ path: "/edit-user/*", component: Login },
	{ path: "/view-profile/:user_id", component: Login },
	{ path: "/dealer-user-listing", component: Login },
	{ path: "/view-report", component: Login },
	{ path: "/map-dealer", component: Login },
	{ path: "/office_address", component: Login },
	{ path: "/edit_office_address", component: Login },
	{ path: "/user/esign_agreement_log_view", component: Login},
	{ path: "/progression-config", component:Login },
	{ path: "/reset_max_attempts", component: Login },
	{ path: "/no-view-report", component: Login },
	{ path : "/role-management", component : Login},
	{ path : "/add-role", component : Login}, 
	{ path : "/view-role/:role_id", component : Login},
	{ path : '/edit-role/:role_id', component : Login},
	{ path : '/progression-dashboard', component : Login},
	{ path : '/admin-user-management', component : Login},
	{ path : '/add-admin-user/*', component : Login},
	{ path : '/view-admin-user/:admin_id', component : Login },	
	{ path : '/edit-admin-user/*', component : Login},
  { path: "/custom-notification", component:  Login},
	{ path: "/create-custom-notification", component: Login}
];
}
if (logged_Data?.token) {
  NavigationRoutes = [
    { path: "/", exact: true, component: Login },
    { path: "/user-management", exact: true, component: UserManagement },
    { path: "/user-management-detail", component: UserManagementDetail },
    { path: "/add-user/*", component: AddUser },
    { path: "/basic-detail-updated", component: BasicDetailUpdated },
    { path: "/document-preview", component: DocumentUplaodPreview },
    { path: "/agent-detail", component: AgentDetails },
    { path: "/view-user/:user_id", component: ViewUser },
    { path: "/edit-user/*", component: EditUser },
    { path: "/view-profile/:user_id", component: ViewUserProfile },
    { path: "/logout", component: logout },
    { path: "/login", component: Login },
    { path: "/role-management", component: RoleManagement },
    { path: "/add-role", component: AddRole },
    { path: "/view-role/:role_id", component: RoleUserDetail },
    { path: "/edit-role/:role_id", component: EditRole },
    { path: "/admin-user-management", component: AdminUserManagement },
    { path: "/add-admin-user/*", component: AddAdminUser },
    { path: "/view-admin-user/:admin_id", component: ViewAdminUser },
    { path: "/edit-admin-user/*", component: EditAdminUser },
    { path: "/remove-info", component: RemoveInfo },
    { path: "/custom-notification", component: CustomNotification },
    { path: "/create-custom-notification", component: CreateCustomNotification },
	{ path: "/reset_max_attempts", component: ResetMaxAttempts },
    ...((TENANT=="id" || TENANT=="tri-id")
      ? [
          { path: "/dealer-user-listing", component: DealerUserListing },
          { path: "/view-report", component: VisitReport },
          { path: "/map-dealer", component: MapDealerManagement },
          { path: "/office_address", component: OfficeAddress },
          { path: "/edit_office_address", component: EditOfficeAddress },
          { path: "/user/esign_agreement_log_view", component: ESIGNLOGVIEW },
          { path: "/progression-config", component: progressionList },
          { path: "/no-view-report", component: NoVisitReport },
          { path: "/progression-dashboard", component: ProgressionDashboard },
          { path: "/sales-target", component: SalesTarget}
        ]
      : []),

    { path: "*", component: PageNotFound },
  ];
}

export default NavigationRoutes;
